@media only screen and (max-width: 1124px) {
  .fleet-card-info {
    order: 2;
  }

  .fleet-card-img {
    order: 1;
    width: 100% !important;
  }

  .vehicle-car-img {
    position: absolute;
    top: 0;
    right: 0;
  }

  .fake-input-tag {
    p {
      font-size: rm(14);
    }
  }

  .benefits-cards {
    justify-content: flex-start;
    gap: rm(30);
  }

  .modal-content {
    .border-right {
      border-right: unset;
    }

    .car-lease-details-menu {
      gap: rm(12);
      align-items: flex-end;

      .car-lease-details-menu-item {
        gap: rm(8);
      }
    }
  }

  .vehicle-info-subtitle {
    margin-bottom: rm(52) !important;
  }

  .vehicle-details-menu {
    gap: rm(20) !important;

    .vehicle-details-menu-item {
      flex-direction: row !important;
      gap: rm(5) !important;

      &>span[class^='ic-'],
      span[class*=' ic-'] {
        &::before {
          font-size: rm(14) !important;
        }
      }

      &>img {
        width: rm(14) !important;
        height: rm(14) !important;
        object-fit: contain;
      }
    }
  }

  .more-tool {
    display: none;

    &.m {
      display: flex !important;
      margin-top: rm(10);
    }
  }

  .location-tab {
    overflow: hidden;
    background-color: $grey;
    display: block;

    button {
      background-color: inherit;
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: rm(42);
      font-weight: 600;
      transition: 0.1s;
      width: 50%;
      // border: rm(0.5) solid $darkgrey;
      border-top: rm(0.5) solid $darkgrey;
      border-bottom: none;
      font-size: rm(14);
      color: $green;

      &:hover {
        background-color: #ddd;
      }

      &:first-child {
        &.active {
          box-shadow: rm(10) 0 rm(10) rm(1) #aaaaaa;
        }
      }

      &.active {
        border-top: rm(2) solid $green;
        color: $black;
        box-shadow: rm(-10) 0 rm(10) rm(1) #aaaaaa;
        position: relative;
        background-color: $white;
      }
    }
  }

  .locationtabcontent {
    display: none !important;
    border-top: none;
  }

  .review-box {
    padding: rm(20) !important;
  }

  .review-form {
    padding: 0 !important;
  }

  .vehicle-info-subtitle {
    max-width: rm(120) !important;
  }

  .d-m-none {
    display: none;
  }

  .m-p-2 {
    padding: rm(14);
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-3 {
    margin-bottom: rm(12);
  }

  .vehicle-info-title {
    &.border-bottom {
      padding-bottom: rm(8);
      border-bottom: rm(1) solid $grey;
      margin-bottom: 0 !important;
      font-weight: 400 !important;
      text-transform: uppercase;
    }

    &.m-size {
      font-size: rm(26) !important;
      font-weight: 400 !important;
    }
  }

  .choose-vehicle-result {
    display: flex;
    gap: rm(5);
  }

  .w-auto {
    width: auto !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .d-m-block {
    display: block !important;
  }

  .return-input {
    display: block !important;
  }
}

@media only screen and (max-width: 1124px) and (min-width: 992px) {
  .vehicle-car-img {
    position: relative !important;
    order: 1 !important;
  }

  .vehicle-card>img {
    width: rm(198) !important;
    height: rm(126) !important;
  }

  .vehicle-info.mr-auto {
    order: 2 !important;
  }

  .vehicle-info {
    order: 3 !important;
  }

  .vehicle-info-total {
    width: auto !important;
  }

  .hidden-vehicle-info {
    order: 4 !important;
  }

  .popover-wrapper {
    .popover {
      right: -48px !important;
      top: 50px !important;
      left: auto !important;
    }
  }

  .m-w-70 {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1224px) and (min-width: 1125px) {
  .vehicle-info-total {
    width: rm(200) !important;
  }
}