.reservation-container {
  width: calc(100% - #{rm(120)});
  margin: 0 auto;
}

.reservation-confirmed {
  padding: rm(85) 0 rm(10);

  .reservation-confirmed-box {
    display: flex;
    gap: rm(20);
    align-items: flex-start;

    .reservation-confirmed-title {
      margin-bottom: rm(10);
      font-size: rm(36);
      font-weight: 400;
      line-height: rm(40);
    }

    .reservation-confirmed-text {
      font-size: rm(18);
      font-weight: 400;
      line-height: rm(24);
    }
  }
}

.pick-return {
  padding-bottom: rm(15);

  .pick-return-box {
    background-color: $white;
    padding: rm(40) 0 rm(30);
    border-radius: rm(12);
    border: rm(1) solid $darkgrey;
    position: relative;
    min-height: rm(180);

    .pick-return-info {
      padding-left: rm(80);

      .pick-return-text {
        font-size: rm(14);
        font-weight: 400;
        line-height: rm(18);
        text-transform: uppercase;
        margin-bottom: rm(10);
      }

      .pick-return-title {
        font-size: rm(18);
        font-weight: 400;
        line-height: rm(24);
      }
    }

    .pick-return-arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modify-reservation {
  padding: rm(15) 0;

  .modify-reservation-text {
    font-size: rm(18);
    line-height: rm(24);
    font-weight: 400;
    margin-bottom: rm(18);
  }

  .modify-reservation-btns {
    display: flex;
    align-items: center;
    gap: rm(30);
    margin-bottom: rm(25);

    .modify-reservation-btn {
      display: flex;
      gap: rm(6);
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      font-size: rm(15);
      font-weight: 400;
      color: $green;
      letter-spacing: rm(0.25);
    }
  }
}

.reservation-btn {
  padding: rm(12) rm(30);
  border-radius: rm(60);
  font-size: rm(14);
  font-weight: 700;
  margin-bottom: rm(14);
  margin-right: rm(10);

  &:last-child {
    margin-right: 0;
  }

  &.colored {
    background-color: $green;
    color: $white;
    border: none;
  }

  &.bordered {
    background-color: transparent;
    border: rm(2) solid $green;
    color: $green;
  }
}

.modify-line {
  border-bottom: rm(1) solid $darkgrey;
  margin-bottom: rm(10);
}

.rental-details {
  padding-bottom: rm(20);

  .rental-details-box {
    width: 100%;
    border-radius: rm(12);
    background-color: $white;
    border: rm(1) solid $darkgrey;

    .rental-details-box-item {
      padding: rm(30);
      border-bottom: rm(1) solid $darkgrey;

      &:last-child {
        border-bottom: none;
      }

      .rental-details-box-title {
        font-size: rm(26);
        font-weight: 400;
        line-height: rm(32);
        color: $black;
      }

      .rental-details-box-subtitle {
        font-size: rm(18);
        font-weight: 400;
        line-height: rm(24);
        color: $black;
        margin-bottom: rm(20);

        &.mb-0 {
          margin-bottom: 0;
        }
      }

      .rental-details-box-text {
        font-size: rm(15);
        font-weight: 400;
        line-height: rm(20);
        color: $black;
        margin-bottom: rm(10);
        display: flex;
        align-items: center;
        gap: rm(10);
      }

      .rental-details-box-link {
        font-size: rm(15);
        font-weight: 400;
        line-height: rm(20);
        color: $green;
        text-decoration: underline;
        margin-bottom: rm(10);
        display: flex;
        align-items: center;
        gap: rm(10);
      }

      .rental-details-box-total {
        display: flex;
        align-items: flex-start;

        .dollar,
        .remider {
          font-size: rm(21);
        }

        .whole {
          font-size: rm(42);
          line-height: rm(36);
        }
      }

      .rental-details-box-ul {
        margin-left: rm(20);
        margin-bottom: rm(20);

        p {
          margin-bottom: rm(4);
        }
      }
    }
  }
}

.rental-box {
  width: 100%;
  border-radius: rm(12);
  background-color: $white;
  border: rm(1) solid $darkgrey;
  margin-bottom: rm(20);
  overflow: hidden;

  .rental-box-header {
    padding: rm(23) rm(20);
    border-bottom: rm(1) solid $darkgrey;
    font-size: rm(21);
    line-height: rm(25);
  }

  .rental-box-body {
    padding: rm(23) rm(20);

    &.min {
      padding: rm(7) rm(20) rm(23);
    }

    &.bg-grey {
      background-color: $grey;
    }
  }

  .rental-box-check-items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: rm(30);

    .rental-box-check-item {
      display: flex;
      align-items: flex-start;
      gap: rm(6);

      p {
        font-size: rm(15);
        line-height: rm(20);
      }
    }
  }

  .rental-box-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .rental-box-link {
      padding: rm(15) 0;
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      color: $green;
      text-decoration: none;
      border-bottom: rm(1) solid $darkgrey;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.rental-details-box {
  margin-bottom: rm(20);
}

.rental-details-accordion {
  border: rm(1) solid $darkgrey;
  border-radius: rm(8);
  overflow: hidden;

  .rental-details-accordion-item {
    &:last-child {
      .rental-details-accordion-body {
        border-bottom: none;
      }
    }
  }

  .rental-details-accordion-header {
    padding: rm(20) rm(17) rm(15) rm(38);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .up {
      display: none;
    }

    &.show {
      .up {
        display: block;
      }

      .down {
        display: none;
      }
    }

    .rental-details-accordion-title {
      font-size: rm(14);
      line-height: rm(18);
      font-weight: 500;
      color: $green;
      text-transform: uppercase;
    }
  }

  .rental-details-accordion-body {
    background-color: $white;
    padding: rm(28) rm(42) rm(44) rm(40);
    border-top: rm(1) solid $darkgrey;
    border-bottom: rm(1) solid $darkgrey;
    display: none;

    &.show {
      display: block;
    }

    .rental-details-accordion-date {
      font-size: rm(18);
      line-height: rm(24);
      margin-bottom: rm(24);
      color: $black;
    }

    .rental-details-accordion-text {
      font-size: rm(15);
      line-height: rm(20);
      margin-bottom: rm(6);
      color: $black;
    }

    .rental-details-accordion-link {
      font-size: rm(15);
      display: block;
      line-height: rm(20);
      margin-bottom: rm(16);
      color: $green;
    }

    .rental-details-accordion-map {
      border-radius: rm(8);
      overflow: hidden;
      margin-bottom: rm(18);

      iframe {
        width: 100%;
        height: rm(230);
        border: none;
      }
    }

    .rental-details-accordion-badge {
      display: flex;
      align-items: center;
      gap: rm(6);
      margin-bottom: rm(10);

      &.disable {
        opacity: 60%;
      }

      p {
        font-size: rm(15);
        line-height: rm(20);
      }
    }
  }
}
