.d-block {
  display: block !important;
}

.stepper-opened-menu {
  width: 100vw;
  background-color: $white;
  border-top: rm(1) solid $darkgrey;

  .stepper-opened-menu-item {
    padding: rm(20) rm(10);
    border-bottom: rm(1) solid $darkgrey;
    min-height: rm(96);

    &.checked {
      .stepper-opened-menu-value,
      .stepper-opened-menu-divider {
        display: block !important;
      }

      .stepper-opened-menu-select {
        display: none !important;
      }
    }

    .stepper-opened-menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rm(5);

      .stepper-opened-menu-title {
        display: flex;
        gap: rm(5);
        align-items: center;

        .stepper-opened-menu-checker {
          width: rm(20);
          height: rm(20);
          border-radius: 50%;
          border: rm(1) solid $darkgrey;
          display: flex;
          justify-content: center;
          align-content: center;

          span {
            font-size: rm(12);
            line-height: rm(18);
          }

          img {
            width: rm(12);
            display: none;
          }
        }

        p {
          font-size: rm(12);
          font-weight: 700;
        }
      }

      .stepper-opened-menu-edit {
        background-color: transparent;
        padding: 0;
        border: 0;
        text-transform: uppercase;
        text-decoration: underline;
        color: $green;
        font-size: rm(12);
        font-weight: 600;
      }
    }

    .stepper-opened-menu-body {
      padding-left: rm(25);
      display: flex;
      align-items: center;
      gap: rm(15);

      .stepper-opened-menu-select {
        font-size: rm(12);
        font-weight: 500;
      }

      .stepper-opened-menu-value {
        font-size: rm(12);
        color: $green;
        font-weight: 600;
        display: none;
      }

      .stepper-opened-menu-divider {
        font-size: rm(12);
        color: $green;
        font-weight: 600;
        display: none;
      }
    }
  }
}
