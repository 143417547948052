@media only screen and (max-width: 1124px) {
  .rental-details-box-text {
    font-size: rm(12) !important;
  }

  .rental-details-accordion-date {
    font-size: rm(14) !important;
  }

  .reservation-btn {
    margin-right: 0;
  }

  .rental-details-box-link {
    display: none !important;
  }

  .reservation-confirmed-box-img {
    margin-bottom: rm(-48);
    z-index: 10;
  }

  .pick-return-box {
    padding: rm(28) rm(20);
  }

  .extras-item {
    flex-direction: row !important;

    .extras-item-title {
      min-width: 0 !important;
      max-width: rm(150);
      order: 1;
    }

    .extras-item-price {
      order: 3;
      width: 100%;
    }

    .more-tool {
      order: 4;
    }

    .change-btn {
      order: 2;
    }

    .extras-info {
      order: 5;
    }

    .included-btn {
      order: 2;
    }
  }

  .extras-title {
    font-size: rm(24) !important;
  }

  .filter-box-title {
    margin-bottom: rm(15);
  }

  .header-reservation {
    display: none;
  }

  .header-reservation-mobile {
    background-color: $green;
    height: rm(60);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-settings {
      display: flex;
      flex-wrap: wrap;
      gap: rm(20);
      align-items: center;
      height: rm(60);

      .header-terms {
        color: $white;
        padding: rm(2) rm(12);
        border: none !important;
        background-color: $darkgreen;
        text-align: center;
        font-size: rm(12);
        font-style: normal;
        font-weight: 700;
        border-radius: rm(30);
        text-transform: uppercase;

        &:hover {
          background-color: $lightgreen;
        }
      }

      .header-total {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #006639;
        min-width: rm(60);
        height: 100%;
        flex-direction: column;
        text-transform: uppercase;

        .total-text {
          color: $white;
          font-size: rm(10);
          font-style: normal;
          font-weight: 400;
        }

        .total-value {
          display: flex;
          cursor: pointer;
          align-items: center;

          .total-value-dollar {
            color: $white;
            font-size: rm(12);
            font-style: normal;
            font-weight: 400;
            line-height: rm(17);
            margin-right: rm(2);
          }

          .total-value-all {
            display: flex;
            flex-wrap: wrap;

            .total-value-whole {
              color: $white;
              font-size: rm(17);
              font-style: normal;
              font-weight: 400;
              line-height: rm(17);
            }

            .total-value-reminder {
              color: $white;
              font-size: rm(12);
              font-style: normal;
              font-weight: 400;
              line-height: rm(17);
            }
          }
        }
      }
    }
  }

  .review {
    .container {
      padding: 0 rm(10) !important;
    }
  }
}

@media only screen and (max-width: 991px) and (min-width: 450px) {
  .flex-wrap {
    flex-wrap: wrap;
  }

  .pick-return-box {
    padding: rm(20) !important;

    .pick-return-arrow {
      display: none;
    }

    .pick-return-info {
      padding-left: 0 !important;
    }
  }

  .reservation-confirmed-box-img {
    margin-bottom: rm(-48);
    z-index: 10;
  }

  .pick-return-box {
    padding: rm(28) rm(20);
  }

  .vehicle-info {
    .vehicle-info-subtitle {
      max-width: unset !important;
    }
  }
}
