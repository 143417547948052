.pick-date-time-select {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.pick-date-calendar.time {
  position: relative;
}

.rental-details-box {
  margin-bottom: rm(20);
}

.rental-details-accordion {
  border: rm(1) solid $darkgrey;
  border-radius: rm(8);
  overflow: hidden;

  .rental-details-accordion-item {
    &:first-of-type {
      border-bottom: rm(1) solid $darkgrey;
    }
    &:last-child {
      .rental-details-accordion-body {
        border-bottom: none;
      }
    }
  }

  .rental-details-accordion-header {
    padding: rm(20) rm(17) rm(15) rm(38);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .up {
      display: none;
    }

    &.show {
      .up {
        display: block;
      }

      .down {
        display: none;
      }
    }

    .rental-details-accordion-title {
      font-size: rm(14);
      line-height: rm(18);
      font-weight: 500;
      color: $green;
      text-transform: uppercase;
    }
  }

  .rental-details-accordion-body {
    background-color: $white;
    padding: rm(28) rm(42) rm(44) rm(40);
    border-top: rm(1) solid $darkgrey;
    border-bottom: rm(1) solid $darkgrey;
    display: none;

    &.show {
      display: block;
    }

    .rental-details-accordion-date {
      font-size: rm(18);
      line-height: rm(24);
      margin-bottom: rm(24);
      color: $black;
      text-transform: capitalize;
    }

    .rental-details-accordion-text {
      font-size: rm(15);
      line-height: rm(20);
      margin-bottom: rm(6);
      color: $black;
      &.dates {
        display: flex;
        justify-content: space-between;
      }
    }

    .rental-details-accordion-link {
      font-size: rm(15);
      display: block;
      line-height: rm(20);
      margin-bottom: rm(16);
      color: $green;
    }

    .rental-details-accordion-map {
      border-radius: rm(8);
      overflow: hidden;
      margin-bottom: rm(18);

      width: 100%;
      height: rm(230);
      border: none;
    }

    .rental-details-accordion-badge {
      display: flex;
      align-items: center;
      gap: rm(6);
      margin-bottom: rm(10);

      &.disable {
        opacity: 60%;
      }

      p {
        font-size: rm(15);
        line-height: rm(20);
      }
    }
  }
}
