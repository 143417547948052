.stepper-editor {
    width: 100vw;
    padding: 0 !important;
    justify-content: flex-start !important;

    .stepper-editor-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: rm(20)2 0 rm(5) !important;
        background: white;
        box-shadow: 0 rm(2) rm(5) 0 $darkgrey;
    }
}

.extras-item {
    border: rm(3) solid transparent;

    .included-btn {
        padding: rm(5) 0;
        width: rm(120);
        border-radius: rm(20);
        justify-content: center;
        align-items: center;
        gap: rm(10);
        display: none;
    }

    &.included {
        background-color: transparent !important;

        .change-btn {
            display: none;
        }

        .included-btn {
            display: flex;

            p {
                font-size: rm(13);
                line-height: rm(10);
            }
        }
    }
}