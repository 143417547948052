@media only screen and (max-width: 991px) {
  /* Default Config */

  .w-50,
  .w-66,
  .w-33 {
    width: 100% !important;
  }

  .text-m-start {
    text-align: start;
  }

  .mb-m-0 {
    margin-bottom: 0;
  }

  .btn {
    min-width: rm(70);
    min-height: rm(25);
    font-size: rm(12);
    font-weight: 400;
    padding: rm(7) rm(15);

    &.big {
      font-size: rm(18);
    }
  }

  .pl-10 {
    padding-left: rm(10);
  }

  .slick-active {
    transform: none !important;
    margin-bottom: 0 !important;
  }

  .choose-your-car-img {
    transform: none !important;
    margin-bottom: rm(30) !important;
  }

  /* Header */
  header {
    .header-menu {
      display: none;
    }

    .mobile-header {
      display: flex;

      & ~ .opened-mobile-menu.show {
        display: flex;
        height: calc(100vh - rm(60));
      }
    }
  }

  /* Preview */

  .preview {
    padding: rm(30) rm(10);
    margin-bottom: rm(28);
    min-height: rm(707) !important;

    &.bg-none {
      background-image: none;
    }

    .form-fields-box {
      .phone-input,
      .input-group {
        &.required::before {
          color: $textDarkgrey;
        }
      }
    }

    &.checked {
      min-height: rm(798) !important;
    }

    .preview-box {
      width: calc(100% - rm(20));
      padding: rm(12) rm(14);
      top: 0;
      left: 0;
      transform: none;
      margin: rm(10);

      .preview-box-header {
        .preview-box-title {
          font-weight: 400;
          width: 100%;
          text-transform: none;
          font-size: rm(28);
        }

        .preview-box-modify {
          width: max-content;
          font-size: rm(13);
        }
      }

      .pick-date-box {
        margin-bottom: rm(15);
        border-top-left-radius: rm(8);
        border-bottom-left-radius: rm(8);
      }
    }
  }

  .date-range-picker-panel {
    .item-bg {
      width: 37vw !important;
    }
  }

  .custom-select-box {
    width: 100%;

    .custom-select {
      max-width: rm(390);
      width: 100%;
      min-width: 0 !important;
    }
  }

  /* Long Term */

  .long-term {
    padding: 0 0 0 rm(18);

    .long-term-offers-box {
      padding: rm(8) 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .offer-card {
        width: 100%;
        .offer-card-img-container {
          .offer-card-img {
            height: rm(160) !important;
          }
        }

        .card-body {
          padding: rm(9) rm(18);
          flex-wrap: wrap;

          .card-text {
            font-size: rm(14) !important;
            margin-bottom: rm(10);
          }

          .btn {
            margin-bottom: rm(10);
          }
        }
      }
    }
  }

  /* Welcome */

  .welcome {
    background-color: $white;

    .welcome-box {
      width: 100%;
      background: none;
      padding: rm(10);
    }

    .advantages {
      padding: 0 auto !important;
    }

    .advantages-box-order {
      order: 2;
      margin: 0 auto;
      width: unset !important;

      .advantage-title {
        font-size: rm(12) !important;
        line-height: rm(18) !important;
      }
    }

    .advantages-img-order {
      order: 1;
    }
  }

  /* Car Rental */

  .car-rental {
    margin-bottom: rm(100);

    .car {
      top: auto !important;
      bottom: rm(-10) !important;
      width: rm(315) !important;
      height: rm(153) !important;
      display: block;
    }

    .car-rental-container {
      padding: rm(30) rm(44) rm(84);
      background-image: none !important;

      .car-rental-title {
        margin-bottom: rm(24);
        text-align: center;
      }

      .car-rental-subtitle {
        margin-bottom: rm(24);
        text-align: center;
      }
    }
  }

  /* Rate */

  .rate {
    background-color: $white;
    margin-bottom: rm(20);

    .rate-info {
      img {
        margin-bottom: rm(30);
      }
    }
  }

  /* Extensive */

  .extensive {
    .extensive-cards {
      flex-wrap: wrap;
      gap: rm(16);

      .extensive-card {
        justify-content: flex-start;
        padding-left: rm(55);
      }
    }
  }

  /* Faq */

  .faq {
    padding: 0 rm(20) rm(40);

    .accordion-box {
      padding: rm(26) 0;
    }
  }

  /* Blog Posts */

  .blog-posts {
    .blog-posts-box {
      width: 100%;
      margin: rm(35) rm(22);

      .blog-posts-border .big-btn {
        padding: rm(15) rm(30);
      }

      .blog-posts-subtitle {
        margin-bottom: rm(22) !important;
      }
    }
  }

  /* Footer */

  .footer {
    padding: rm(40) rm(30);

    .footer-left {
      margin-bottom: rm(64);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* ------------- Landing Pages ------------- */

  /* Default Config */

  .justify-m-center {
    justify-content: center;
  }

  /* Landing Preview */

  .landing-preview {
    margin-bottom: rm(62);

    .landing-img-box {
      height: rm(100);
      margin-bottom: rm(34);
    }
  }

  /* Welcome to Our */

  .welcome-our {
    padding: 0 rm(20);
    margin-bottom: rm(48);
  }

  /* One Way Rentals */

  .one-way-rentals {
    padding: rm(15) rm(10) rm(30) rm(10);
  }

  .mobile-img {
    object-fit: cover !important;
    height: unset !important;
  }

  /* Tramsportation Services */

  .collaborated {
    padding: 0 rm(20);

    .collaborated-carousel {
      margin-bottom: rm(30);
    }
  }

  /* About Us */

  .about-us {
    padding: rm(80) 0 rm(12);

    .about-us-car {
      top: rm(25);
      left: rm(-150);
    }
  }

  .info {
    padding: rm(40) 0;

    .info-text {
      padding: 0;
    }
  }

  .info-cards .row .w-50 {
    width: 100% !important;
  }

  /* Popover */
  // .popover {
  //   overflow: hidden;
  // }

  /* Car Lease */

  .car-lease-preview {
    padding: rm(30) 0;
  }

  .popular-cars {
    padding: 0;
  }

  .more-options {
    padding: 0 rm(20) rm(20);
  }

  /* Find a Reservation */

  .find-reservation-preview {
    padding: rm(30) rm(10);
  }

  .find-reservation-form {
    padding: 0 rm(20);
    margin-bottom: rm(104);

    .find-reservation-box {
      .find-reservation-box-title {
        font-size: rm(17) !important;
      }
    }
  }

  /* Month and More */

  .custom-date-range-picker {
    .months-container {
      .month-container {
        padding: rm(10) 0 !important;
        width: 100%;

        table {
          display: flex;
          flex-direction: column;

          .week-day,
          .calendar-day {
            width: 2.4rem !important;
            height: 2.4rem !important;
          }

          .calendar-day-container {
            width: 2.4rem !important;
            height: 2.4rem !important;
          }
        }
      }
    }
  }

  .range-toolbar {
    padding: rm(34) rm(40) rm(34) rm(40) !important;
    width: fit-content;
  }

  .special-offers,
  .month-more-preview {
    padding: rm(30) 0;
  }

  .extensive {
    padding: 0 rm(20);
    margin-bottom: rm(28);
  }

  .month-more-info {
    padding: 0 rm(20);
    margin-bottom: rm(60);

    .month-more-info-box {
      max-width: 100%;
      word-wrap: break-word;
    }
  }

  /* Rent a car in Yerevan */

  .rent-car-yerevan {
    padding: rm(30) 0;

    .line {
      margin-top: rm(20);
    }
  }

  /* Default Config */

  .flex-m-wrap {
    flex-wrap: wrap;
  }

  .w-m-100 {
    width: 100%;
  }

  /* Fleet */

  .fleet {
    padding: rm(30) 0;

    .fleet-title {
      margin-bottom: rm(16);
    }

    .fleet-cards {
      .fleet-card {
        padding: rm(32) rm(16) rm(30);

        .fleet-card-header {
          flex-wrap: wrap;

          .fleet-card-title {
            width: 100%;
          }
        }

        .fleet-card-body {
          flex-wrap: wrap;
          justify-content: center;
        }

        .fleet-card-footer {
          margin-top: rm(20);
        }
      }
    }
  }
}

@media only screen and (max-width: 1124px) {
  .card-body {
    padding: rm(9) rm(18);
    flex-wrap: wrap;

    .card-text {
      min-height: rm(40);
    }

    .card-info {
      margin-bottom: rm(15);
    }
  }

  .date-range-picker-panel {
    .item-bg {
      width: fit-content !important;
    }
  }

  .modal-body {
    padding: rm(20) !important;
  }

  .stepper {
    display: none;
  }

  .choose-location-info {
    flex-wrap: wrap;

    .choose-location-results {
      flex-wrap: wrap;

      .choose-location-result {
        width: 100%;
      }

      .change-location {
        display: none !important;
      }
    }
  }

  .stepper-mobile {
    padding: rm(11) rm(48) rm(30) rm(18);
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $white;
    border-bottom: rm(1) solid $darkgrey;

    .stepper-mobile-item {
      width: rm(20);
      height: rm(20);
      border-radius: 50%;
      border: rm(1) solid $darkgrey;
      color: $darkgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rm(11);
      font-weight: 400;
      position: relative;

      p {
        display: none;
      }

      &:first-child {
        &.active {
          p {
            left: 0;
            transform: translate(0, 0);
          }
        }
      }

      &:last-child {
        &.active {
          p {
            right: -100%;
            transform: translate(-92%, 0);
          }
        }
      }

      &.active {
        background-color: $black;
        border-color: $black;
        color: $white;

        p {
          display: flex;
          position: absolute;
          left: 50%;
          bottom: rm(-24);
          transform: translate(-50%, 0);
          color: $black;
          text-wrap: nowrap;
        }
      }

      img {
        display: none;
      }

      &.checked {
        span {
          display: none;
        }

        img {
          display: block;
        }
      }
    }

    .stepper-menu {
      width: rm(20);
      height: rm(20);
      border-radius: 50%;
      background-color: $green;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media print {
  body {
    header,
    .stepper,
    .extras,
    .modal-header,
    .stepper-mobile,
    .choose-vehicle {
      display: none;
    }

    footer {
      .tab,
      .footer-terms,
      .footer-divider,
      .footer {
        display: none;
      }

      .terms-modal {
        .modal-header {
          display: none;
        }
      }
    }

    .printable {
      .print-btn {
        display: none;
      }

      width: 100% !important;
    }

    main {
      .rental-details > .reservation-container > .row > .w-33,
      .modify-reservation {
        display: none;
      }
    }

    .reservation-confirmed,
    .pick-return,
    .rental-details > .reservation-container > .row > .w-66 {
      display: block;
    }
  }
}
