@media only screen and (max-width: 991px) {
  .header-reservation {
    padding: rm(10) rm(20);
  }

  .header-navbar {
    display: none !important;
  }

  .reservation-title {
    font-size: rm(26);
    font-weight: 400;
    text-transform: none;
  }

  .stepper {
    display: none;
  }

  .choose-location-info {
    flex-wrap: wrap;

    .choose-location-results {
      flex-wrap: wrap;

      .choose-location-result {
        width: 100%;
      }

      .change-location {
        display: none !important;
      }
    }
  }

  .choose-location-tools {
    display: none !important;
  }

  .select-location {
    .select-location-cards {
      width: 100%;
      padding: rm(18);
    }

    .select-location-map {
      width: 100%;
    }
  }

  .choose-location {
    background-color: $grey;
  }

  .stepper-mobile {
    padding: rm(11) rm(48) rm(30) rm(18);
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: $white;
    border-bottom: rm(1) solid $darkgrey;

    &>div>.popover-wrapper {
      position: unset !important;

      &>.popover {
        left: 0 !important;
        top: 100% !important;
        width: 100vw !important;
      }
    }

    .stepper-mobile-item {
      width: rm(20);
      height: rm(20);
      border-radius: 50%;
      border: rm(1) solid $lightgrey;
      color: $lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rm(11);
      font-weight: 400;
      position: relative;

      p {
        display: none;
      }

      &:first-child {
        &.active {
          p {
            left: 0;
            transform: translate(0, 0);
          }
        }
      }

      &:last-child {
        &.active {
          p {
            right: -100%;
            transform: translate(-92%, 0);
          }
        }
      }

      &.active {
        background-color: $black;
        border-color: $black;
        color: $white;

        p {
          display: flex;
          position: absolute;
          left: 50%;
          bottom: rm(-24);
          transform: translate(-50%, 0);
          color: $black;
          text-wrap: nowrap;
        }
      }

      img {
        display: none;
      }

      &.checked {
        span {
          display: none;
        }

        img {
          display: block;
        }
      }
    }

    .stepper-menu {
      width: rm(20);
      height: rm(20);
      position: absolute;
      background-color: $green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .choose-location-mobile-tools {
    margin-top: rm(18);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: rm(7);

    .btn-tools {
      padding: rm(8) rm(14);
      border: rm(1) solid $lightgrey;
      border-radius: rm(30);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rm(5);

      img {
        width: rm(11);
        height: rm(11);
      }

      p {
        color: $green;
        font-size: rm(15);
        font-weight: 400;
        line-height: rm(18);
      }
    }
  }

  .choose-vehicle {
    padding: rm(20) rm(10);

    .filter-container {
      display: none;
    }

    .vehicle-container {
      width: 100%;

      .vehicle-info {
        width: 100%;

        .vehicle-info-total {
          width: 100% !important;
          padding: rm(10) 0 rm(16) !important;
        }
      }
    }
  }

  .review-container {
    width: 100% !important;
  }

  .reserve-container {
    width: 100% !important;
  }

  .extras {
    max-width: 100%;
    padding: rm(20) rm(10);

    .extras-items-header {
      display: none;
    }

    .extras-item {
      flex-direction: column;
      gap: rm(10);
      padding: rm(20) !important;
    }
  }

  .footer {
    padding: rm(20);

    .footer-left {
      width: 100%;

      .footer-left-menu {
        word-break: break-all;
      }
    }
  }

  .blog-posts-box {
    .blog-posts-border {
      padding: rm(20);

      .blog-posts-subtitle {
        width: 100%;
        word-break: break-word;
      }
    }
  }

  .offer-card {
    .card-body {
      flex-wrap: wrap;

      .card-title {
        font-size: rm(20) !important;
      }

      .card-text {
        font-size: rm(12) !important;
        margin-bottom: rm(10) !important;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .vehicle-card {
    justify-content: center !important;
    padding: rm(20) rm(14) !important;
  }

  .more-tool {
    margin-bottom: rm(10);
  }

  .vehicle-details-menu {
    justify-content: start;
  }

  .underline-m-none {
    text-decoration: none !important;
  }

  .vehicle-card .hidden-vehicle-info.show {
    flex-wrap: wrap;
  }

  .popover {
    min-height: rm(250);

    .stepper-editor-box {
      padding: rm(20) rm(20) rm(50);

      .stepper-editor-title {
        margin-bottom: rm(6);
      }

      .date-title {
        font-weight: 500 !important;
      }
    }
  }

  .frame-box-info {
    flex-wrap: wrap;

    button {
      width: 100%;
    }
  }

  .p-m-0 {
    padding: 0 !important;
  }

  .pt-m-0 {
    padding-top: 0 !important;
  }

  .pl-m-0 {
    padding-left: 0 !important;
  }

  .gap-m-6 {
    gap: rm(6);
  }

  .mt-m-20 {
    margin-top: rm(20);
  }

  .w-m-100 {
    width: 100%;
  }
}

@media only screen and (max-width: 1124px) and (min-width: 992px) {
  .choose-vehicle .filter-container .filter-box {
    padding: rm(20);
  }

  .radiobutton {
    padding-left: rm(36);
    font-size: rm(12);
    font-weight: 500;

    .checkmark {
      left: rm(5);
    }
  }

  .select-selected {
    font-size: rm(12);
  }

  .vehicle-details-menu {
    gap: rm(40) !important;
  }

  .vehicle-card {
    padding: rm(20) rm(15);
  }

}

@media only screen and (max-width: 1240px) and (min-width: 992px) {
  .review {

    .review-container,
    .reserve-container {
      width: 100% !important;
    }
  }
}