.disable-scroll {
  position: fixed;
  inline-size: 100%;
  overflow-y: scroll;
}

.row-basic {
  display: flex;
  flex-wrap: wrap;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.form-fields {
  .popover-wrapper {
    .popover {
      width: 100%;

      .frame {
        left: 0;
        z-index: 2;
        top: rm(27);
        width: inherit;
        overflow-y: auto;
        position: absolute;
        max-height: rm(208);
        padding: rm(7) rm(24);
        background-color: $white;
        border-radius: 0 0 rm(20) rm(20);
        box-shadow: 0 rm(4) rm(4) 0 rgba(0, 0, 0, 0.25);

        .frame-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: rm(10) 0;

          .frame-box-icon {
            & > span {
              font-size: rm(24);
              margin: 0 rm(12);
            }
          }

          .frame-box-info {
            width: 100%;
            padding: rm(14) rm(20) rm(6);
            background-color: $grey;
            height: 100%;
            border-radius: rm(12);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: rm(10);

            &.bc-none {
              background-color: transparent;
            }

            .frame-box-info-desc {
              display: flex;
              flex-direction: column;
              gap: rm(7);

              .frame-box-title {
                color: $black;
                font-size: rm(20);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

.date-range-picker-panel {
  .date-range-picker-panel-box {
    overflow: hidden;

    &.fit {
      .item-bg {
        width: fit-content !important;
      }

      width: fit-content;
    }
  }
}

.custom-datepicker {
  .date-range-picker-panel-box {
    width: 874px;
  }

  .popover {
    padding-bottom: 20px;
  }
}

.stepper {
  .stepper-item {
    .item-box {
      width: -webkit-fill-available;
      max-width: fit-content;
    }

    .stepper-item-info-sub {
      text-align: left;
      width: max-content;

      p {
        white-space: pre;
      }
    }
  }
  .popover {
    .preview {
      min-height: unset !important;
      .preview-box {
        position: unset;
        transform: unset;
      }
    }
  }
}

.choose-location-info {
  .choose-location-results {
    .change-location {
      cursor: pointer;
    }
  }

  .choose-location-tools {
    cursor: pointer;
  }
}

.location-btn {
  cursor: pointer;
}

.review-form {
  .error {
    .form-control {
      border: rm(1.5) solid rgb(248, 149, 149) !important;
    }
  }
}

.modal-scroll {
  overflow: auto;
  max-height: 80vh;
}

.phone-input {
  .react-tel-input {
    margin-bottom: rm(14);

    .form-control {
      border-radius: 0 !important;
      padding-left: rm(40) !important;
      font-family: $DINAlternate !important;
    }

    .flag-dropdown {
      border: unset !important;
      background-color: unset !important;
    }

    .selected-flag {
      background: unset !important;

      .arrow {
        display: none;
      }
    }
  }
}

body {
  &:has(main > div.not-found) {
    header,
    footer {
      display: none;
    }
  }
  &:has(.not-found) {
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: $lightgreen;
    .not-found {
      color: $white;
      $notFoundFont: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji' !important;
      h1 {
        display: inline-block;
        margin: 0 rm(20) 0 0;
        padding: 0 rm(23) 0 0;
        font-size: rm(24);
        font-weight: 500;
        vertical-align: top;
        line-height: rm(49);
        text-align: center;
        font-family: $notFoundFont;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
      & > div {
        display: inline-block;
        h2 {
          margin: 0;
          font-size: rm(14);
          font-weight: 400;
          line-height: rm(49);
          font-family: $notFoundFont;
        }
      }
    }
  }
}

.offers-page {
  padding: rm(89) rm(64) 0 rm(64);

  .offers-box {
    gap: rm(14);
    display: flex;
    flex-wrap: wrap;
    border-radius: rm(10);

    .offer-card {
      margin-bottom: rm(39);
      width: calc((100vw - #{rm(142)}) / 2);
      .offer-card-img-container {
        width: 100%;
        position: relative;
        .offer-card-img {
          width: 100%;
          object-fit: cover;
          border-radius: rm(10);
        }
      }

      .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rm(25) rm(30) rm(0) rm(30);

        .card-info {
          .card-title {
            color: $black;
            font-size: rm(24);
            font-weight: 700;
            margin-bottom: rm(8);
            line-height: rm(38);
          }

          .card-text {
            color: $black;
            font-size: rm(17);
            font-style: normal;
            font-weight: 700;
            line-height: rm(15);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .offers-page {
    padding: rm(45) rm(23) rm(86) rm(23);

    .offers-box {
      display: flex;
      flex-wrap: wrap;

      .offer-card {
        width: 100%;
        margin-bottom: rm(6);
        .offer-card-img-container {
          .offer-card-img {
            height: rm(160) !important;
          }
        }

        .card-body {
          padding: rm(9) rm(18);
          flex-wrap: wrap;

          .card-title {
            line-height: unset !important;
            margin-bottom: rm(2) !important;
          }

          .card-text {
            margin-bottom: 0 !important;
            min-height: unset !important;
            font-size: rm(14) !important;
            line-height: unset !important;
          }

          .card-info {
            margin-bottom: 0 !important;
          }

          .btn {
            width: unset;
          }
        }
      }
    }
  }
}

.long-term-offers-box {
  .no-bg {
    background-color: unset;
  }
}

.offer-inner {
  display: flex;
  padding-top: rm(100);
  flex-direction: column;
  padding-bottom: rm(150);

  .paragraph {
    padding-top: rm(40);
    font-weight: 400;
    font-size: rm(14);
    line-height: rm(24);

    * {
      font-family: $DINAlternate;
    }
    p {
      margin: 0;
      font-weight: 400;
      color: $black;
      font-size: rm(14);
      line-height: rm(24);
    }

    a {
      color: $hardGreen;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 991px) {
  .offer-inner {
    padding-top: rm(36);
    padding-bottom: rm(57);

    .paragraph {
      order: 1;
      padding-top: 0;
    }

    img {
      order: 2;
      margin-top: rm(25);
      border-radius: rm(12);
    }

    .preview {
      order: 3;
      padding: 0;
      margin-top: rm(20);
      .preview-box {
        margin: 0;
      }
    }
  }
}
