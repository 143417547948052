/* Default Styles */

.bgc-none {
  background-color: transparent;
}

/* Breadcrumb */

.breadcrumb {
  width: 100%;
  min-height: rm(42);
  padding: 0 rm(60);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: rm(1) solid $darkgrey;

  .breadcrumb-link {
    color: $lightgreen;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-right: rm(26);
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: '/';
      position: absolute;
      top: 50%;
      right: rm(-18);
      transform: translate(-50%, -50%);
      color: $darkgrey;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.active {
      color: $black;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: none;
      }
    }
  }
}

/* Fleet Info */

.fleet-info {
  padding: rm(75) 0 rm(56);

  .fleet-info-title {
    color: $lightBlack;
    font-size: rm(36);
    font-style: normal;
    font-weight: 400;
  }

  .fleet-info-text {
    color: $lightBlack;
    font-size: rm(18);
    font-style: normal;
    font-weight: 400;
  }
}

.fleet-info-details {
  .fleet-info-details-title {
    color: $lightBlack;
    font-size: rm(24);
    font-style: normal;
    font-weight: 400;
    border-bottom: rm(4) solid $lightBlack;
    padding-bottom: rm(12);
    margin-bottom: rm(36);
  }

  .fleet-info-details-text {
    color: $lightBlack;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    margin-bottom: rm(30);
  }

  .fleet-info-details-features {
    margin-left: rm(20);
  }

  .fleet-info-details-list {
    display: flex;
    flex-direction: column;

    .fleet-info-details-list-item {
      display: flex;
      gap: rm(11);
      margin-bottom: rm(30);

      & > p {
        color: $black;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

/* Blog */

.blog {
  padding: rm(88) 0 rm(52);

  .blog-title {
    color: $black;
    font-size: rm(24);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: rm(570);
    margin-bottom: rm(15);
  }

  .blog-text {
    color: $black;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    line-height: rm(24);
    margin-bottom: rm(60);

    & > a {
      color: $lightgreen;
      text-decoration: underline;
    }

    * {
      text-align: justify;
    }
  }

  .blog-img {
    width: 100%;
    border-radius: rm(12);
    margin-bottom: rm(44);
  }
}

/* Contact Us */

.contact-us {
  padding: rm(58) 0 rm(94);

  .contact-us-card {
    width: 100%;
    padding: rm(30) rm(10) rm(54);
    border: rm(1) solid $darkgrey;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 rm(1) rm(3) rm(0) rgba($color: $black, $alpha: 0.2);

    .contact-us-card-icon {
      margin-bottom: rm(24);
    }

    .contact-us-card-link {
      color: $hardGreen;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      margin-bottom: rm(14);
    }
  }

  .line {
    width: 100%;
    height: rm(1);
    background-color: $lightgreen;
  }

  .contact-us-box {
    display: flex;
    gap: rm(20);
    align-items: flex-start;
    margin-bottom: rm(18);

    & > p,
    & > a {
      color: $black;
      font-size: rm(16);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
    }
  }
}
