.gap {
  gap: rm(8);
}

.more-tool {
  cursor: pointer;
  &.rotate {
    span[class*=' ic-'],
    img {
      transform: rotate(180deg);
    }
  }
}

.extras-item {
  cursor: pointer;
  flex-wrap: wrap;

  .extras-info {
    display: none;
    margin-top: rm(35);
    width: 100%;
    font-size: rm(13);
    flex-direction: column;

    &.show {
      display: flex !important;
    }

    .extras-line {
      width: 100%;
      margin-bottom: rm(13);
      border-top: rm(2) solid $grey;
    }
  }
}

.hours-services-modal {
  width: 100%;
  top: rm(20);
  left: rm(20);
  overflow-x: auto;
  position: absolute;
  max-width: rm(430);
  border-radius: rm(8);
  background-color: $white;
  max-height: calc(100% - #{rm(40)});

  .hours-services-modal-header {
    align-items: center;
    background-color: $hardGreen;
    border-top-left-radius: rm(8);
    border-top-right-radius: rm(8);
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: rm(50);
    padding: rm(8) rm(27);

    p {
      text-transform: uppercase;
      font-size: rm(14);
      font-weight: 700;
      color: $white;
    }
  }

  .hours-services-modal-body {
    padding: rm(20);

    .hours-services-city {
      font-size: rm(15);
      font-weight: 700;
      margin-bottom: rm(10);
    }

    .hours-services-items {
      padding-bottom: rm(15);
      margin-bottom: rm(15);
      border-bottom: rm(1) solid $grey;

      .hours-services-item {
        gap: rm(10);
        display: flex;
        margin-bottom: rm(10);
        align-items: center;

        & > span[class*=' ic-'] {
          &::before {
            font-size: rm(24);
            color: #757575 !important;
          }
        }

        & > img {
          object-fit: contain;
        }

        & > div {
          font-size: rm(15);
          color: $black;
          line-height: rm(25);
          display: flex;
          align-items: center;
          gap: rm(10);
        }
      }
    }

    .hours-services-calendar {
      .hours-services-calendar-title {
        font-size: rm(15);
        font-weight: 700;
        text-align: center;
        margin-bottom: rm(15);
      }

      .hours-services-calendar-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $grey;
        padding: rm(10);
        border-radius: rm(4);
      }
    }
  }
}

.vehicle-card {
  border: rm(2) solid transparent;
  position: relative;
  overflow: hidden;
  align-items: start !important;

  .vehicle-card-selected {
    display: none !important;
    width: 100%;
    height: rm(33);
    background-color: $green;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 rm(10);
    display: flex;
    align-items: center;
    gap: rm(6);

    span[class*=' ic-'] {
      &::before {
        color: $white;
        display: flex;
        width: rm(24);
        height: rm(24);
        font-size: rm(16);
        align-items: center;
        justify-content: center;
      }
    }

    p {
      font-size: rm(16);
      color: $white;
      font-weight: 700;
    }
  }

  &.selected {
    border: rm(2) solid $green;
    padding-top: rm(50) !important;

    .vehicle-card-selected {
      display: flex !important;
    }
  }

  .hidden-vehicle-info {
    display: none;
    border-top: rm(2) solid $grey;
    margin-top: rm(20);

    &.show {
      display: flex;
    }
  }

  .vehicle-info {
    .vehicle-info-text {
      font-size: rm(12);
      color: $textDarkgrey;
      max-width: rm(564);
    }
  }
}

.stepper {
  position: relative;

  .stepper-editor {
    z-index: 10;
    width: 100vw;
    align-items: center;
    flex-direction: column;
    padding: rm(20) 0 rm(5);
    justify-content: center;
    border-top: rm(3) solid $grey;

    .stepper-editor-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rm(30);

      .stepper-editor-title {
        font-size: rm(24);
        font-weight: 700;
      }
    }
  }

  .stepper-item {
    cursor: pointer;

    &.edit::after {
      position: absolute;
      width: rm(200);
      height: rm(4);
      content: '';
      background: green;
      bottom: 0;
    }
  }
}

.different-location-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: rm(12);
  margin-bottom: rm(24);

  .different-location-checkbox {
    padding: rm(2);
    width: rm(24);
    height: rm(24);
    border: rm(2) solid $lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .different-location-text {
    color: $lightBlack;
    font-size: rm(14);
    font-style: normal;
    font-weight: 700;
    line-height: rm(20);
    margin-bottom: 0;
  }
}
