[class^='ic-'],
[class*=' ic-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: var(--icoFont) !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-arrow-left:before {
  content: '\e931';
  color: $hardGreen;
}
.ic-arrow-right:before {
  content: '\e932';
  color: $hardGreen;
}
.ic-airplane-active:before {
  content: '\e900';
}
.ic-arrow-drop-down:before {
  content: '\e901';
  color: #489860;
}
.ic-arrow-drop-up:before {
  content: '\e902';
  color: #489860;
}
.ic-arrow-drow-down:before {
  content: '\e903';
  color: #fff;
}
.ic-benefits-1:before {
  content: '\e904';
  color: #fff;
}
.ic-benefits-2:before {
  content: '\e905';
  color: #169a5a;
}
.ic-benefits-3:before {
  content: '\e906';
  color: #169a5a;
}
.ic-benefits-4:before {
  content: '\e907';
  color: #169a5a;
}
.ic-benefits-5:before {
  content: '\e908';
  color: #169a5a;
}
.ic-benefits-6:before {
  content: '\e909';
  color: #169a5a;
}
.ic-benefits-7:before {
  content: '\e90a';
  color: #169a5a;
}
.ic-benefits-9:before {
  content: '\e90c';
  color: #169a5a;
}
.ic-benefits-10:before {
  content: '\e90d';
  color: #169a5a;
}
.ic-benefits-11:before {
  content: '\e90e';
  color: #169a5a;
}
.ic-car-left-parameter-icon .path1:before {
  content: '\e90f';
  color: rgb(255, 255, 255);
}
.ic-car-left-parameter-icon .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ic-car-left-parameter-icon .path3:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.ic-car-right-parameter-icon .path1:before {
  content: '\e912';
  color: rgb(255, 255, 255);
}
.ic-car-right-parameter-icon .path2:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ic-car-right-parameter-icon .path3:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(5, 5, 5);
}
.ic-check-circle-outline:before {
  content: '\e915';
  color: #489860;
}
.ic-checkbox:before {
  content: '\e916';
  color: #489860;
}
.ic-chevron-right:before {
  content: '\e918';
  color: $hardGreen;
}
.ic-directions-car:before {
  content: '\e91a';
  color: #489860;
}
.ic-drop-down-white:before {
  content: '\e91b';
  color: #fff;
}
.ic-email:before {
  content: '\e91c';
  color: $hardGreen;
}
.ic-flag:before {
  content: '\e91d';
  color: #489860;
}
.ic-footer-email:before {
  content: '\e91e';
  color: $hardGreen;
}
.ic-footer-location:before {
  content: '\e91f';
  color: $hardGreen;
}
.ic-footer-phone:before {
  content: '\e920';
  color: $hardGreen;
}
.ic-globe:before {
  content: '\e921';
  color: #fff;
}
.ic-Hamburger:before {
  content: '\e922';
  color: #fff;
}
.ic-info .path1:before {
  content: '\e923';
  color: rgb(22, 154, 90);
}
.ic-info .path2:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ic-location-city:before {
  content: '\e925';
}
.ic-location-on:before {
  content: '\e926';
  color: #489860;
}
.ic-lock:before {
  content: '\e927';
  color: #6e6e6e;
}
.ic-menu-drop-down:before {
  content: '\e928';
  color: $hardGreen;
}
.ic-phone:before {
  content: '\e929';
  color: $hardGreen;
}
.ic-popular-cars-menu-2:before {
  content: '\e92b';
  color: #757575;
}
.ic-popular-cars-menu-3:before {
  content: '\e92c';
}
.ic-popular-cars-menu-4:before {
  content: '\e92d';
}
.ic-popular-cars-menu-5:before {
  content: '\e92e';
}
.ic-specs-passenger-gray:before {
  content: '\e92f';
  color: #6e6e6e;
}
.ic-supervised-user-circle:before {
  content: '\e930';
  color: #489860;
}
