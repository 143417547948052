/* Modals Config */

.modal {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: none;
  overflow: auto;
  padding: rm(20);
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);

  &.open {
    display: flex;
  }
}

.modal-content {
  width: 100%;
  margin: auto;
  border: none;
  overflow: hidden;
  max-width: rm(1065);
  border-radius: rm(12);
  background-color: $white;

  &.min {
    max-width: rm(760);
  }

  .modal-header {
    width: 100%;
    height: rm(55);
    background-color: $hardGreen;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 rm(16);
  }

  .modal-body {
    padding: rm(25) rm(40) rm(40) rm(45);
    overflow-x: auto;

    &.min-padding {
      padding: rm(20);
    }

    .modal-title {
      color: $black;
      font-size: rm(20);
      font-style: normal;
      font-weight: 700;
      letter-spacing: rm(0.5);
      text-transform: uppercase;
      margin-bottom: rm(28);
    }

    .modal-subtitle {
      color: $black;
      font-size: rm(20);
      line-height: rm(40);
      font-style: normal;
      font-weight: 700;

      &.font-light {
        font-weight: 400;
      }
    }

    .modal-mini-text {
      color: $black;
      font-size: rm(18);
      line-height: rm(24);
    }

    .modal-hr {
      width: 100%;
      height: rm(1);
      background: #aeaeae;
      margin-bottom: rm(14);
    }

    .modal-spantext {
      color: #565656;
      font-size: rm(20);
      font-style: normal;
      font-weight: 400;
      line-height: rm(40);
    }

    .modal-btn-group {
      margin-top: rm(108);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      gap: rm(24);

      &.justify-between {
        justify-content: space-between !important;
      }
    }

    .cancel-or {
      color: $textDarkgrey;
      font-size: rm(20);
    }

    .rounded-btn {
      min-width: rm(274);
      min-height: rm(45);
      border-radius: rm(60);
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: rm(14);
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &.passive {
        background-color: $lightgrey !important;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .modal-list-title {
      color: #565656;
      font-size: rm(14);
      margin-bottom: rm(10);
    }

    .modal-list-ul {
      color: #565656;
      font-size: rm(20);
      margin-left: rm(20);
      margin-bottom: rm(20);
    }

    .modal-total-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-total-item {
        color: #565656;
        font-size: rm(15);
        margin-bottom: rm(20);
        max-width: rm(530);
      }
    }

    .fee-details {
      margin-bottom: rm(15);

      .taxes-fees {
        color: $green;
        font-size: rm(20);
        text-decoration-line: underline;
        display: flex;
        align-items: center;
      }
    }

    .modal-estimated-total {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: rm(22);

      .modal-estimated-total-item {
        max-width: rm(500);
        color: $black;
        font-size: rm(24);
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .modal-info {
      color: #565656;
      font-size: rm(14);
    }
  }
}

.close {
  color: $white;
  font-size: rm(28);
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: $black;
  text-decoration: none;
  cursor: pointer;
}

/* Default */

.border-right {
  border-right: rm(1) solid $darkgrey;
}

/* Car Lease Modal */

.car-lease-details-modal {
  padding-bottom: rm(34);
  border-bottom: rm(1) solid $darkgrey;
  margin-bottom: rm(15);

  .car-lease-details-title {
    color: $black;
    font-size: rm(20);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 !important;
    margin-bottom: rm(15) !important;
  }

  .car-lease-details-subtitle {
    color: $black;
    font-size: rm(14);
    font-style: normal;
    font-weight: 700;
    margin-bottom: rm(5);

    &.upper {
      text-transform: uppercase;
    }
  }

  .car-lease-details-more {
    color: #787878;
    font-size: rm(12);
    font-style: normal;
    font-weight: 400;
    margin-bottom: rm(20);
  }

  .car-lease-details-menu {
    display: flex;
    flex-wrap: wrap;
    gap: rm(70);
    align-items: center;
    margin-bottom: rm(18);

    .car-lease-details-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: rm(18);

      &.small {
        & > img {
          height: rm(10);
        }

        [class^='ic-'],
        [class*=' ic-'] {
          &::before {
            font-size: rm(10);
          }
        }
      }

      & > img {
        height: rm(19);
      }

      [class^='ic-'],
      [class*=' ic-'] {
        &::before {
          color: #757575;
          font-size: rm(19);
        }
      }

      & > p {
        color: $black;
        font-size: rm(13);
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .inclusions-ul {
    margin: rm(7) 0 rm(15);
    display: flex;
    flex-direction: column;
    gap: rm(5);

    .inclusions-li {
      display: flex;
      gap: rm(6);

      & > p {
        color: $textDarkgrey;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .car-lease-modal {
    display: flex;
    flex-direction: column;

    & > h1 {
      color: $black;
      font-size: rm(16);
      font-style: normal;
      font-weight: 700;
      margin: rm(12) 0 rm(7) 0;
    }

    &.price {
      & > h1 {
        line-height: 1.5;
        margin: rm(20) 0 0 rm(4);
      }
    }

    &.badges {
      .car-lease-modal-badge {
        padding: rm(5.5) rm(14) rm(5.5) rm(14);
        background-color: #169a5a42;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: rm(40);
        margin-bottom: rm(8);

        & > h1 {
          margin: 0;
          width: 40%;
          line-height: 1;
          font-weight: 400;
          font-size: rm(11);
          color: $lightBlack;
        }

        & > p {
          color: $black;
          line-height: 1.25;
          font-size: rm(12);
        }
      }
    }

    .monthly-price {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      h1 {
        color: $black;
        font-size: rm(13);
        font-style: normal;
        font-weight: 400;
        margin: 0;
      }

      p {
        color: $black;
        font-size: rm(20);
        font-style: normal;
        line-height: 1;
      }
    }
  }
}

@media only screen and (max-width: 1124px) {
  .modal-body {
    padding: rm(14) !important;

    .modal-subtitle {
      margin: 0 !important;
    }

    .modal-list-ul,
    .modal-total-item,
    .taxes-fees,
    .fee-details p {
      font-size: rm(14) !important;
    }

    .modal-estimated-total-item {
      font-size: rm(18) !important;
      max-width: rm(410) !important;
    }

    .modal-info {
      font-size: rm(10) !important;
    }
  }

  .terms-modal {
    .min-padding {
      padding: rm(10) !important;
      display: flex;
      flex-direction: column;
    }

    .tab,
    .tab-content {
      width: 100% !important;
      padding: 0 !important;
      border-right: unset !important;
    }
  }

  .modal-mini-text {
    margin-bottom: rm(10);
  }

  .custom-datepicker {
    .date-range-picker-panel-box {
      width: 100% !important;

      .item-bg {
        width: 100% !important;

        .date-range {
          width: 100% !important;
        }
      }
    }
  }
}
