.reservation-title {
  color: $black;
  font-size: rm(32);
  font-weight: 700;
  margin: 0 !important;
  text-transform: uppercase;
}

.review-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: rm(22);

  .reservation-btn {
    margin-bottom: 0;
  }
}

.reservation-subtitle {
  color: $black;
  font-size: rm(24);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: rm(32);
}

.more-tool {
  display: flex;
  gap: rm(8);
  cursor: pointer;

  p {
    color: $green;
    font-size: rm(13);
    font-style: normal;
    font-weight: 400;
    line-height: rm(17);
    text-decoration: underline;
  }

  .active {
    display: none;
  }
}

.custom-select-box {
  &.reservation {
    margin-bottom: rm(16);
  }
}

.radiobutton {
  display: block;
  position: relative;
  padding: rm(16) 0 rm(16) rm(72);
  margin-bottom: rm(12);
  cursor: pointer;
  font-size: rm(22);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: rm(16);
  font-style: normal;
  font-weight: 400;

  .row {
    justify-content: space-between;
  }

  &:hover {
    background-color: $darkgrey;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: rm(24);
    height: rm(24);
    width: rm(24);
    border: rm(3) solid $grey;
    border-radius: 0.25rem;
    transform: translate(0, -50%);

    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: $grey;
  }

  input:checked ~ .checkmark {
    background-color: $green;
    border-color: $green;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: calc(50% - rm(2));
    left: 50%;
    width: rm(5);
    height: rm(10);
    border: solid $white;
    border-width: 0 rm(3) rm(3) 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

/* Header */

.header-reservation {
  background-color: $darkgreen;
  height: rm(60);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 rm(50) 0 rm(60);

  .header-logo img {
    width: rm(168);
  }

  .header-settings {
    display: flex;
    flex-wrap: wrap;
    gap: rm(20);

    .header-terms {
      color: $white;
      padding: rm(5) rm(25);
      border: none !important;
      background-color: $green;
      text-align: center;
      font-size: rm(14);
      font-style: normal;
      font-weight: 700;
      border-radius: rm(30);

      &:hover {
        background-color: $lightgreen;
      }
    }

    .header-total {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: rm(10);

      .total-text {
        color: $white;
        font-size: rm(17.6);
        font-style: normal;
        font-weight: 600;
      }

      .total-value {
        display: flex;
        cursor: pointer;
        align-items: center;

        .total-value-dollar {
          color: $white;
          font-size: rm(20);
          font-style: normal;
          font-weight: 400;
          line-height: rm(34);
          margin-right: rm(4);
        }

        .total-value-all {
          display: flex;
          flex-wrap: wrap;

          .total-value-whole {
            color: $white;
            font-size: rm(34);
            font-style: normal;
            font-weight: 400;
            line-height: rm(34);
          }

          .total-value-reminder {
            color: $white;
            font-size: rm(20);
            font-style: normal;
            font-weight: 400;
            line-height: rm(34);
          }
        }
      }
    }
  }
}

/* Stepper */

.stepper {
  padding: rm(50) rm(50) rm(30) rm(60);
  background-color: $white;
  display: flex;
  align-items: flex-start;
  gap: rm(8);

  & > .popover-wrapper {
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    position: absolute;

    &.open {
      display: unset;
    }

    & > .popover {
      left: 0 !important;
    }
  }

  .stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: fit-content;

    .stepper-item-title {
      display: flex;
      align-items: center;
      gap: rm(8);
      margin-bottom: rm(22);

      &.active {
        img {
          display: block;
        }

        .stepper-child {
          display: none;
        }
      }

      .stepper-child {
        width: rm(25);
        height: rm(25);
        border-radius: 50%;
        background-color: $lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: rm(12);
        font-style: normal;
        font-weight: 400;
      }

      img {
        width: rm(25);
        height: rm(25);
        display: none;
      }

      p {
        color: $black;
        font-size: rm(14);
        font-style: normal;
        font-weight: 500;
      }
    }

    .stepper-item-info {
      display: flex;
      flex-direction: column;
      gap: rm(16);

      p {
        color: $black;
        font-size: rm(12);
        font-style: normal;
      }
    }
  }

  .stepper-line {
    width: 100%;
    height: rm(1);
    background-color: $grey;
    margin-top: rm(12.5);
  }
}

/*  Choose Location*/

.choose-location {
  padding: rm(20);
  background-color: $white;

  .choose-location-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .choose-location-results {
      display: flex;
      gap: rm(22);
      align-items: center;

      .choose-location-result {
        color: $black;
        font-size: rm(17);
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        & > span {
          color: $textDarkgrey;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .change-location {
        display: flex;
        align-items: center;
        gap: rm(4);
        color: $green;
        font-size: rm(17);
        font-style: normal;
        font-weight: 700;
        line-height: rm(24);

        img {
          width: rm(17);
          height: rm(18);
        }
      }
    }

    .choose-location-tools {
      display: flex;
      align-items: center;
      gap: rm(20);

      .choose-location-tool {
        display: flex;
        gap: rm(5);
        align-items: center;

        p {
          color: $green;
          font-size: rm(15);
          font-style: normal;
          font-weight: 700;
          line-height: rm(24);
        }

        span[class^='ic-'],
        span[class*=' ic-'] {
          &::before {
            display: flex;
            font-size: rm(11);
          }
        }
      }
    }
  }
}

/* Select Location */

.select-location {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: rm(600);

  .select-location-cards {
    width: 30%;
    overflow-y: auto;
    max-height: rm(600);
    padding: rm(17) rm(15) rm(17) rm(25);
    display: flex;
    flex-direction: column;
    gap: rm(10);

    .select-location-card {
      background-color: $white;
      border-radius: rm(12);
      box-shadow: 0 0 rm(10) rm(1) $textDarkgrey;

      .line {
        width: 100%;
        height: rm(1);
        background-color: $grey;
      }

      .select-location-card-full {
        padding: rm(10);
        display: flex;
        gap: rm(10);
      }

      .select-location-card-badges {
        display: flex;
        flex-direction: column;
        gap: rm(5);

        .pin-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          width: rm(27);
          height: rm(31);
          background-image: url(../images/icons/Pin.svg);
          background-size: cover;
          background-position: center;
          color: $white;
          font-size: rm(12);
          font-style: normal;
          line-height: rm(18);
          font-weight: 700;
        }
      }

      .select-location-title {
        color: $black;
        font-size: rm(18);
        font-style: normal;
        font-weight: 700;
        line-height: rm(30);
        margin-bottom: rm(12);
      }

      .select-location-address {
        color: $black;
        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(18);
        margin-bottom: rm(10);
      }

      .select-location-card-buttons {
        padding: rm(20) rm(10) rm(20) rm(47);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.end {
          justify-content: flex-end;
        }

        .hours-services {
          color: $green;
          cursor: pointer;
          font-size: rm(14);
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          gap: rm(5);

          span[class^='ic-'],
          span[class*=' ic-'] {
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              font-size: rm(10);
            }
          }
        }

        .location-btn {
          padding: rm(5) rm(15);
          color: $white;
          font-size: rm(14);
          font-weight: 700;
          text-transform: uppercase;
          background-color: $green;
          border: none;
        }
      }
    }
  }

  .select-location-map {
    width: 70%;
    height: rm(600);
    position: relative;

    .search-move {
      z-index: 10;
      top: rm(20);
      right: rm(62);
      display: flex;
      height: rm(30);
      gap: rm(10);
      padding: 0 rm(10);
      position: absolute;
      border-radius: rm(3);
      align-items: center;
      justify-content: center;
      background-color: $white;

      .search-move-box {
        width: rm(16);
        display: flex;
        height: rm(16);
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: rm(2) solid $green;

        span[class*=' ic-'] {
          position: relative;

          &::before {
            top: rm(-5.5);
            left: rm(-5.5);
            height: rm(11);
            font-size: rm(11);
            position: absolute;
          }
        }

        &.disabled {
          img {
            display: none;
          }
        }
      }

      .search-move-text {
        color: $black;
        font-size: rm(14);
      }
    }
  }
}

/* Choose Vehicle */

.choose-vehicle {
  padding: rm(15) rm(46) rm(40);

  .filter-container {
    width: 25%;

    .filter-box {
      padding: rm(16) rm(16);
      background-color: $white;
      border-radius: rm(12);

      .filter-box-title {
        color: $black;
        font-size: rm(16);
        font-style: normal;
        font-weight: 700;
        margin-bottom: rm(18);
      }
    }
  }

  .vehicle-container {
    width: calc(75% - rm(24));

    .vehicle-cards {
      display: flex;
      flex-direction: column;
      gap: rm(12);
      margin-top: rm(23);

      .vehicle-card {
        padding: rm(21) rm(36) rm(21) rm(14);
        background-color: $white;
        border-radius: rm(12);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        & > img {
          width: rm(198);
          height: rm(126);
        }

        .vehicle-info {
          .vehicle-info-title {
            color: $black;
            font-size: rm(16);
            font-style: normal;
            font-weight: 700;
            margin-bottom: rm(8);

            &.big {
              font-size: rm(20);
            }
          }

          .vehicle-info-subtitle {
            color: $black;
            font-weight: 400;
            font-size: rm(14);
            max-width: rm(317);
            font-style: normal;
            margin-bottom: rm(24);
          }

          .vehicle-details-menu {
            display: flex;
            flex-wrap: wrap;
            gap: rm(74);
            align-items: center;
            margin-bottom: rm(18);

            .vehicle-details-menu-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: rm(18);

              & > span[class^='ic-'],
              span[class*=' ic-'] {
                &::before {
                  color: #757575;
                  font-size: rm(24);
                }
              }

              & > img {
                width: rm(24);
                height: rm(24);
                object-fit: contain;
              }

              & > p {
                color: $black;
                font-size: rm(13);
                font-style: normal;
                font-weight: 400;
              }
            }
          }

          .vehicle-info-total {
            width: rm(250);
            padding: rm(26) 0 rm(34);
            display: flex;
            flex-direction: column;
            gap: rm(12);

            .vehicle-info-promotion {
              gap: rm(6);
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                font-weight: 800;
                font-size: rm(14);
                line-height: rm(18);
                color: $textDarkgrey;
              }
            }

            .vehicle-info-total-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                color: $lightBlack;
                font-size: rm(13);
                font-style: normal;
                font-weight: 400;
              }

              h3 {
                color: $lightBlack;
                font-size: rm(20);
                font-style: normal;
                font-weight: 500;
              }
            }

            .vehicle-info-total-line {
              width: 100%;
              height: rm(1);
              background-color: $green;
            }
          }
        }
      }
    }
  }
}

.map-marker {
  width: rm(30);
  height: rm(30);
  display: flex;
  position: relative;
  align-items: center;
  border-radius: rm(1);
  justify-content: center;
  background-color: $green;

  &::before {
    content: ' ';
    width: rm(6);
    height: rm(6);
    bottom: rm(-3);
    position: absolute;
    background-color: $green;
    transform: rotate(225deg);
  }

  span {
    color: $white;
    font-weight: 700;
    font-size: rm(12);
    line-height: rm(20);
  }

  &.closed {
    background-color: $black;

    &::before {
      background-color: $black;
    }
  }
}
