/* Reservation Extras */

.continue-btn {
  padding: rm(8) rm(50);
  background-color: $green;
  color: $white;
  font-size: rm(13);
  font-style: normal;
  font-weight: 400;
  line-height: rm(16);
  display: flex;
  align-items: center;
  border-radius: rm(20);
  border: none !important;
  justify-content: center;
  min-width: rm(95) !important;
}

.change-btn {
  padding: rm(5) 0;
  width: rm(120);
  border-radius: rm(20);
  border: rm(1) solid $green;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rm(10);

  & > p {
    color: $green;
    font-size: rm(13);
    font-style: normal;
    font-weight: 400;
  }

  &:hover {
    .add {
      color: $white;
    }
  }

  .remove,
  .remove-icon {
    display: none;
  }
}

.extras {
  padding: rm(55) 0 rm(75);
  max-width: rm(940);
  margin: 0 auto;

  .extras-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rm(18);

    .extras-title {
      margin: 0;
      color: $black;
      font-size: rm(32);
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .extras-warning {
    width: 100%;
    background-color: $lightBlack;
    padding: rm(4) rm(9);
    color: $white;
    font-size: rm(13);
    font-style: normal;
    font-weight: 400;
    margin-bottom: rm(8);
  }

  .extras-items-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rm(245);
    margin-bottom: rm(20);

    .extras-items-header-title {
      color: $black;
      font-size: rm(16);
      font-style: normal;
      font-weight: 700;
      min-width: rm(170);
    }

    .extras-items-header-price {
      color: $lightBlack;
      font-size: rm(16);
      font-style: normal;
      font-weight: 700;
    }
  }

  .extras-items {
    display: flex;
    flex-direction: column;
    gap: rm(14);
    margin-bottom: rm(30);

    .extras-item {
      padding: rm(30) rm(50);
      background-color: $white;
      border-radius: rm(12);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 rm(10) rm(30) 0 rgba(0, 0, 0, 0.04);

      .extras-item-title {
        width: rm(272);
        color: $black;
        font-size: rm(16);
        font-style: normal;
        font-weight: 700;
      }

      .extras-item-price {
        color: $lightBlack;
        font-size: rm(15.2);
        font-style: normal;
        font-weight: 400;
      }

      &.active {
        background-color: $green;

        p,
        h3 {
          color: $white;
        }

        .change-btn {
          border-color: $white;

          .add,
          .add-icon {
            display: none;
          }

          .remove,
          .remove-icon {
            display: block;
          }
        }

        .more-tool {
          span[class^='ic-'],
          span[class*=' ic-'] {
            &:before {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.edit-btn {
  border: rm(1) solid $green;
  border-radius: rm(30);
  min-width: rm(50);
  padding: 0 rm(6);
  height: rm(24);
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $green;
  font-size: rm(14);
  font-style: normal;
  font-weight: 400;
}

.btn.mini {
  min-width: auto;
  width: rm(102);
  height: rm(30);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: rm(14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Review and reverse */

.review {
  padding: rm(50) 0 rm(70);

  .container {
    padding: 0 rm(20) !important;
  }

  .review-container {
    width: 35%;
  }

  .reserve-container {
    width: 64%;
  }

  .review-box {
    padding: rm(30);
    position: relative;
    border-radius: rm(12);
    background-color: $white;
    margin-bottom: rm(18);

    .review-box-links {
      display: flex;
      flex-direction: column;
      gap: rm(4);

      .review-box-link {
        display: flex;
        cursor: pointer;
        padding: rm(15) 0;
        font-size: rm(15);
        color: $lightgreen;
        align-items: center;
        justify-content: space-between;
        border-bottom: rm(2) solid $grey;

        span[class^='ic-'],
        span[class*=' ic-'] {
          &::before {
            color: #b2b2b2;
            font-size: rm(12);
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .review-title {
      margin-bottom: rm(12);

      .review-box-detail-info-text {
        margin-bottom: 0 !important;
      }
    }

    .review-box-detail-info-text {
      color: $black;
      font-size: rm(15);
      font-style: normal;
      font-weight: 400;
      line-height: rm(18);
      margin-bottom: rm(6);
      display: flex;
      align-items: center;
      gap: rm(6);
      max-width: rm(250);

      &.m-w-unset {
        max-width: unset;
      }

      &.dropdown {
        color: $green;
        cursor: pointer;

        &.show {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }

    .review-box-title {
      color: $black;
      font-size: rm(26);
      font-style: normal;
      font-weight: 400;
      line-height: rm(32);
      margin-bottom: rm(22);
    }

    .review-box-details {
      display: flex;
      flex-direction: column;
      gap: rm(42);

      .review-box-detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .review-box-detail-info {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .vehicle-car-details {
      display: flex;
      flex-direction: column;

      .vehicle-car-details-title {
        color: $lightBlack;
        font-size: rm(18);
        font-style: normal;
        font-weight: 400;
        line-height: rm(36);
      }

      .vehicle-car-details-subtitle {
        color: $lightBlack;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
        line-height: rm(28);
      }

      .vehicle-car-details-type {
        color: #4f4f4f;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
        line-height: rm(28);
        gap: rm(8);
        display: flex;
        align-items: center;
      }
    }

    .vehicle-car-img {
      width: rm(170);
      height: rm(89);
    }

    .review-taxes {
      padding: rm(15) 0;
      margin: rm(20) 0;
      border-top: rm(2) solid $grey;
      border-bottom: rm(2) solid $grey;
    }

    .estimated-total {
      display: flex;
      gap: rm(4);
      align-items: flex-start;

      .text {
        color: $black;
        font-size: rm(22);
        font-style: normal;
        font-weight: 400;
        line-height: rm(20);
      }

      .total {
        color: $black;
        font-size: rm(42);
        font-style: normal;
        font-weight: 400;
        line-height: rm(32);
      }
    }

    .review-form {
      padding: rm(10);
      display: flex;
      flex-direction: column;

      .review-form-title {
        color: $black;
        font-size: rm(20);
        font-style: normal;
        font-weight: 700;
        margin-bottom: rm(12);
      }

      .review-form-subtitle {
        color: #787878;
        font-size: rm(12);
        font-weight: 400;
        margin-bottom: rm(25);
      }

      .review-form-text {
        color: $black;
        font-size: rm(16);
        font-style: normal;
        font-weight: 700;
        margin-bottom: rm(23);
      }

      .review-input {
        display: flex;
        flex-direction: column;

        label {
          color: $black;
          font-size: rm(15);
          font-style: normal;
          font-weight: 700;
          line-height: rm(20);
          margin-bottom: rm(10);
        }

        input,
        select {
          width: 100%;
          height: rm(44);
          padding: 0 rm(10);
          margin-bottom: rm(10);
          border: rm(2) solid #efefef;
        }
      }
    }
  }
}
