@media only screen and (max-width: 1124px) {
  .flex-wrap {
    flex-wrap: wrap;
  }

  .reservation-container {
    width: 100%;
    padding: 0 rm(10);
  }

  .reservation-btns {
    flex-direction: column;
    width: 100%;
  }

  .reservation-confirmed {
    padding: rm(20) 0;
  }

  .pick-return-box {
    padding: rm(20) !important;
  }

  .pick-return-info {
    padding-left: 0 !important;
  }

  .pick-return-arrow {
    display: none;
  }

  .rental-details-box-item {
    padding: rm(12) !important;
  }

  .rental-details-accordion-header {
    padding: rm(12) !important;
  }

  .rental-details-accordion-body {
    padding: rm(12) !important;
  }

  .rental-box-header {
    padding: rm(12) !important;
  }

  .rental-box-body {
    padding: rm(12) !important;
  }
}
