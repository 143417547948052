.more-tool.m {
  display: none;
}

.location-tab {
  display: none;
}

.pl-2 {
  padding-left: rm(10);
}

.h-auto {
  height: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.error-title {
  font-size: rm(32);
  font-weight: 400;
}

.fake-input {
  width: 100%;
  color: $black;
  border-radius: 0;
  background: $white;
  border: rm(2) solid $darkgrey;
  font-size: rm(19);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: rm(4) rm(12);
  height: rm(50);
  margin-bottom: rm(14);
  cursor: text;

  .fake-input-tag {
    padding: 0 rm(10);
    background-color: $grey;
    width: max-content;
    height: 100%;
    border-radius: rm(8);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rm(12);

    .icon {
      cursor: pointer;
    }

    .fake-input-line {
      width: rm(1);
      height: 50%;
      background-color: $lightgrey;
    }
  }
}

body {
  &.overlay {
    main::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: $black, $alpha: 0.7);
    }

    header {
      position: relative;
      z-index: 10;
    }

    .stepper,
    .stepper-mobile {
      z-index: 10;
    }
  }
}

.fleet-info-details-text {
  color: $lightBlack;
  font-size: rm(14);
  font-style: normal;
  font-weight: 400;
  margin-bottom: rm(30);
}

.fleet-info-details-features {
  margin-left: rm(20);
}

.fleet-info-details-list {
  display: flex;
  flex-direction: column;

  .fleet-info-details-list-item {
    display: flex;
    gap: rm(11);
    margin-bottom: rm(30);

    & > p {
      color: $black;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
