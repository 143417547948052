/* Check Prices */

.check-prices {
  padding: rm(105) rm(20) rm(65) rm(20);
  max-width: rm(950);
  margin: 0 auto;

  .check-prices-header {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: rm(18);
    margin-bottom: rm(24);

    .check-prices-title {
      color: $lightBlack;

      font-size: rm(36);
      font-style: normal;
      font-weight: 400;
      line-height: rm(32);
      text-transform: uppercase;
    }

    .check-prices-or {
      color: $lightgrey;

      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(16);
      text-transform: uppercase;
    }

    .check-prices-modify {
      color: $lightgreen;

      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(18);
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }

  .different-location-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: rm(12);
    margin-bottom: rm(24);

    .different-location-checkbox {
      padding: rm(2);
      width: rm(24);
      height: rm(24);
      border: rm(2) solid $lightgreen;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .different-location-text {
      color: $lightBlack;
      font-size: rm(14);
      font-style: normal;
      font-weight: 700;
      line-height: rm(20);
      margin-bottom: 0;
    }
  }
}

/* Explore Similar */

.explore-similar {
  .explore-similar-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .explore-similar-item {
      max-width: rm(300);
      width: 100%;
      border-top: rm(6) solid $darkgrey;
      padding: rm(45) rm(15) rm(70);

      .explore-similar-item-title {
        color: $lightgreen;
        text-align: center;
        font-size: rm(28);
        font-style: normal;
        font-weight: 400;
        line-height: rm(28);
        text-decoration-line: underline;
        margin-bottom: rm(5);
      }

      .explore-similar-item-subtitle {
        color: $lightBlack;
        text-align: center;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
        line-height: rm(20);
      }

      .explore-similar-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rm(15);

        .explore-similar-menu-item {
          display: flex;
          align-items: center;
          gap: rm(5);

          & > p {
            color: $lightBlack;
            font-size: rm(14);
            font-weight: 400;
          }
        }
      }

      .vehicle-features {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rm(16);
        margin-bottom: rm(16);

        & > p {
          color: $lightgreen;
          font-size: rm(14);
          font-weight: 400;
          line-height: rm(22);
          text-transform: uppercase;
        }
      }
    }
  }
}
