* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $DINAlternate;
  outline: none;
}

body {
  background-color: $grey;
}

label {
  sup {
    top: rm(-4);
    color: rgb(240, 141, 141);
  }
}

/* Default Styling */

.section-title {
  color: $softBlack;
  font-size: rm(32);
  font-weight: 700;
  line-height: rm(32);
  text-transform: uppercase;
  margin-bottom: rm(15);
}

.section-subtitle {
  color: $softBlack;
  text-align: center;
  font-size: rm(15);
  font-style: normal;
  font-weight: 400;
  margin-bottom: rm(10);
}

/* Slick Settings */

.slick-slide > div {
  padding: rm(6);
}

.slick-track {
  width: max-content !important;
}

.slick-arrow {
  width: rm(40);
  height: rm(40);

  &.slick-prev {
    left: rm(-40);
    transform: rotate(90deg);

    &::before {
      content: '\e928';
      font-size: rm(40);
      color: #00000054;
      font-family: var(--icoFont) !important;
    }
  }

  &.slick-next {
    right: rm(-40);
    transform: rotate(270deg);

    &::before {
      content: '\e928';
      font-size: rm(40);
      color: #00000054;
      font-family: var(--icoFont) !important;
    }
  }
}

/* Choose Your Car Slick Settings */

.choose-your-car-carousel {
  margin-bottom: rm(50);

  .slick-next,
  .slick-prev {
    position: absolute;
    z-index: 10;
    top: calc(100% + rm(52));
    display: block !important;

    &::before {
      width: rm(20);
      color: $green;
      height: rm(20);
      font-size: large;
    }
  }

  .slick-prev {
    left: calc(50% - rm(170));
  }

  .slick-next {
    right: calc(50% - rm(170));
  }
}

// .slick-arrow {
//   display: none !important;
// }

.slick-dots {
  display: flex;
  justify-content: center;
  gap: rm(18);

  li {
    width: rm(38) !important;
    height: rm(3) !important;

    button {
      width: rm(38) !important;
      height: rm(3) !important;

      &::before {
        width: rm(38) !important;
        height: rm(3) !important;
        border-radius: 0;
        background-color: $textDarkgrey;
        color: transparent !important;
        opacity: 1 !important;
      }
    }
  }

  li.slick-active button::before {
    background-color: $green;
  }
}

/* Grid System */

.container {
  max-width: rm(1240);
  margin: 0 auto;
  padding: 0 rm(20);
}

.row-reverse {
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.row-basic {
  flex-direction: row;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: rm(12);

  &.gap {
    gap: rm(24);
  }
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: calc(50% - (#{rm(24)} / 2)) !important;
}

.w-66 {
  width: calc(((100% / 3) * 2) - (#{rm(24)} - ((#{rm(24)} + #{rm(24)}) / 3)));
}

.w-33 {
  width: calc((100% / 3) - ((#{rm(24)} + #{rm(24)}) / 3));
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.p-0 {
  padding: 0 !important;
}

.pb-2 {
  padding: rm(32);
}

.pv-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: rm(8);
}

.mb-3 {
  margin-bottom: rm(36);
}

.mb-5 {
  margin-bottom: rm(48);
}

.mt-5 {
  margin-top: rm(48);
}

.mt-3 {
  margin-top: rm(36);
}

.pt-20 {
  padding-top: rm(20) !important;
}

/* Buttons */

.btn {
  cursor: pointer;
  padding: rm(12) rm(12);
  background-color: $hardGreen;
  color: $white;
  border: rm(1) solid transparent;
  border-radius: rm(60);
  min-width: rm(135);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: rm(15);
  font-style: normal;
  font-weight: 700;
  line-height: rm(20);
  letter-spacing: rm(0.25);

  &.big {
    padding: rm(12) rm(45);
  }

  &.power {
    padding: rm(12) rm(90);
  }

  &:hover {
    background-color: $darkgreen;
    border: rm(1) solid $green;
  }

  &.disabled {
    background-color: $darkgrey;
    color: $textDarkgrey;
  }
}

.select-btn {
  min-width: rm(114);
  height: rm(40);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rm(60);
  border: rm(2) solid $hardGreen;
  color: $hardGreen;
  cursor: pointer;
  font-size: rm(18);
  font-style: normal;
  font-weight: 700;

  &:hover {
    background-color: $darkgreen;
    border: rm(2) solid $green;
    color: $white;
  }
}

/* Form Fields */

.form-fields-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  h3 {
    font-weight: 900;
    margin-bottom: rm(8);
  }

  .car-lease-details-more {
    color: #787878;
    font-size: rm(12);
    font-style: normal;
    font-weight: 400;
    margin-bottom: rm(20);
  }

  .form-fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: rm(24);
  }

  label {
    color: $lightBlack;
    font-size: rm(15);
    font-style: normal;
    font-weight: 700;
    line-height: rm(15);
    display: inline-block;
    margin-bottom: rm(10);
    text-transform: capitalize;
  }

  &.text-transform-0 {
    label {
      text-transform: none !important;
    }
  }

  .error {
    .form-control {
      border: rm(2) solid rgb(248, 149, 149) !important;
    }
  }

  .phone-input,
  .input-group {
    position: relative;

    &.required::before {
      content: attr(data-value);
      right: 0;
      top: rm(-24);
      color: #c3c3c3;
      font-weight: 600;
      font-size: rm(15);
      position: absolute;
      font-style: normal;
      line-height: rm(24);
    }

    select {
      appearance: none;
    }

    .form-control {
      width: 100%;
      border-radius: 0;
      border: rm(2) solid $darkgrey;
      background: $white;
      color: $black;
      font-size: rm(19);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: rm(12);
      height: rm(50);
      margin-bottom: rm(14);

      ::placeholder {
        color: $lightgrey;

        font-size: rm(19);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

.forms-ul {
  padding-left: rm(26);
  margin: rm(20) 0;
}

.return-input {
  display: none;
}

/* Header */

.header-navbar {
  background-color: $darkgreen;
  height: rm(60);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 rm(50) 0 rm(60);

  .header-logo img {
    width: rm(168);
  }

  .header-settings {
    display: flex;
    flex-wrap: wrap;
    gap: rm(70);

    a {
      [class^='ic-'],
      [class*=' ic-'] {
        &::before {
          font-size: rm(16);
        }
      }

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: rm(3);
      color: $white;

      font-size: rm(12.4);
      font-style: normal;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

body {
  &:has(.header-menu .header-menu-nav:hover > .header-menu-overlay) {
    overflow: hidden;
  }
}

.header-menu {
  height: rm(70);
  background-color: $lightgreen;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  .header-menu-nav {
    &:hover > .header-menu-link {
      background-color: $white;
      color: $black;

      [class^='ic-'],
      [class*=' ic-'] {
        &::before {
          color: $black;
        }
      }
    }

    &:hover > .header-menu-overlay {
      display: flex;
    }

    .header-menu-link {
      height: rm(70);
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      text-align: center;
      font-size: rm(19);
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
      padding: 0 rm(44);
      border-right: rm(1) solid $darkgreen;

      [class^='ic-'],
      [class*=' ic-'] {
        margin-left: rm(3);

        &::before {
          color: $white;
          font-size: rm(13);
        }
      }
    }

    .header-menu-overlay {
      left: 0;
      z-index: 1;
      top: rm(70);
      width: 100%;
      height: 100vh;
      overflow: hidden;
      position: absolute;
      justify-content: space-between;
      background-color: rgba($color: $black, $alpha: 0.7);

      .header-menu-opened-box {
        width: 100%;

        .header-menu-dropdown-menu {
          width: 100%;
          display: flex;
          overflow: hidden;
          height: max-content;
          background-color: $white;
          justify-content: space-between;
          border-bottom-left-radius: rm(12);
          border-bottom-right-radius: rm(12);
        }

        .header-menu-opened-box-wrapper {
          padding: rm(54) rm(42);

          & > h1 {
            margin: 0;
            font-weight: 600;
            font-size: rm(24);
            line-height: rm(24);
            margin-bottom: rm(30);
          }

          .header-menu-opened-box-container {
            display: flex;
            height: fit-content;

            .header-vertical-divider {
              width: rm(2);
              height: rm(90);
              margin-right: rm(50);
              background-color: $grey;
            }

            .header-menu-opened-box-links {
              display: flex;
              flex-direction: column;

              &.first {
                margin-right: rm(165);
              }

              .header-menu-opened-box-link {
                color: $green;
                font-size: rm(16);
                font-style: normal;
                font-weight: 400;
                line-height: rm(24);
                cursor: pointer;
                text-decoration-line: underline;
                margin-bottom: rm(16);

                &:hover {
                  color: $black;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Mobile Header */

.mobile-header {
  width: 100%;
  height: rm(60);
  background-color: $lightgreen;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-left: rm(10);

  .logo {
    width: rm(120);
  }

  .mobile-menu {
    width: rm(60);
    height: rm(60);
    background-color: $darkgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}

.opened-mobile-menu {
  width: 100%;
  display: none;
  height: 0;
  background-color: $white;
  flex-direction: column;

  .opened-mobile-menu-header {
    width: 100%;
    padding: rm(6.5) rm(5);
    background-color: $darkgreen;
    display: flex;
    justify-content: flex-end;
    margin-bottom: rm(28);

    .popover {
      position: fixed !important;
      top: rm(107) !important;
      left: 0 !important;
      width: 100vw;
      height: calc(100vh - rm(107));
      background-color: $white;

      .picker-panel {
        padding: rm(33) rm(25);
        display: flex;
        flex-direction: column;

        .picker-panel-title {
          font-size: rm(22);
          line-height: rm(20);
          font-weight: 400;
          text-transform: uppercase;
          margin-bottom: rm(33);
        }

        label {
          margin-bottom: rm(24);
          font-size: rm(16);
          line-height: rm(20);
          font-weight: 400;
          accent-color: $hardGreen;

          input {
            margin-right: rm(25);
          }
        }
      }
    }

    .opened-mobile-menu-header-item {
      padding: rm(8) rm(14);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rm(6);
      font-size: rm(14);
      line-height: rm(18);
      font-weight: 400;
      color: $white;

      span {
        font-size: rm(16);
      }

      p {
        text-decoration: underline;
        text-underline-offset: rm(2);
      }
    }
  }

  .opened-mobile-menu-body {
    padding: 0 rm(30) 0 rm(24);
    display: flex;
    flex-direction: column;
    gap: rm(32);
    transition: all 1s;
    height: 100%;
    overflow-y: auto;
    padding-bottom: rm(28);
  }

  .opened-mobile-menu-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    .opened-mobile-menu-link {
      width: 100%;
      cursor: pointer;
      color: $lightgreen;
      font-size: rm(20);
      font-style: normal;
      font-weight: 400;
      line-height: rm(18);
      position: relative;
      text-transform: uppercase;
      justify-content: space-between;

      &:hover {
        opacity: 0.8;
      }

      &::after {
        right: 0;
        content: '\e928';
        font-size: rm(14);
        position: absolute;
        font-family: var(--icoFont) !important;
      }

      &.open {
        &::after {
          transform: rotate(180deg);
        }
      }

      &.single {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .expanded-links {
    display: none;
    position: relative;

    &.open {
      display: inline;
      margin-top: rm(-16);
    }

    a {
      display: block;
      text-decoration: none;
    }

    .expanded-link {
      padding: rm(8);
      cursor: pointer;
      color: $lightgreen;

      &::after {
        right: 0;
        content: '\e928';
        font-size: rm(14);
        position: absolute;
        transform: rotate(-90deg);
        font-family: var(--icoFont) !important;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

/* Preview */

.preview {
  padding: rm(67) 0 rm(105);
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: rm(564);

  .w-66 {
    &:not(.search-input) {
      .popover {
        width: unset !important;

        .date-range-picker-panel-box {
          overflow: unset !important;

          .item-bg {
            overflow: unset !important;

            .date-range {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  .preview-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  .btn {
    margin-top: rm(30);
  }

  &.bg-lg-none {
    background-image: none;
  }

  .preview-box {
    width: rm(1000);
    padding: rm(38) rm(65) rm(40);
    background-color: $white;
    border-radius: rm(8);
    position: absolute;
    top: 42.4%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .preview-box-header {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: rm(18);
      margin-bottom: rm(24);

      .preview-box-title {
        margin: 0;
        color: $lightBlack;
        font-size: rm(36);
        font-style: normal;
        font-weight: 700;
        line-height: rm(32);
        text-transform: uppercase;
      }

      .preview-box-or {
        color: $lightgrey;

        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(16);
        text-transform: uppercase;
      }

      .preview-box-modify {
        color: $lightgreen;

        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(18);
        text-decoration-line: underline;
        text-transform: uppercase;
      }
    }

    .preview-error {
      width: 100%;
      padding: rm(8) rm(20);
      background-color: $yellow;
      color: $lightBlack;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(18);
      margin-bottom: rm(30);
    }

    .different-location-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: rm(12);
      margin-bottom: rm(24);

      .different-location-checkbox {
        padding: rm(2);
        width: rm(24);
        height: rm(24);
        border: rm(2) solid $lightgreen;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        [class^='ic-'],
        [class*=' ic-'] {
          &::before {
            font-size: rm(18);
            color: $lightgreen;
          }
        }
      }

      .different-location-text {
        color: $lightBlack;
        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(20);
        margin-bottom: 0;
      }
    }
  }
}

.pick-date-box {
  height: rm(50);
  border: rm(2) solid $darkgrey;
  display: flex;

  .pick-date-calendar {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-right: rm(2) solid $darkgrey;

    &.time {
      position: relative;
      border-right: none;
      background-color: $grey;

      .pick-date-time-select {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
      }

      & > label {
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }

    .date-title {
      font-size: rm(44);
      font-weight: 700;
      line-height: 1;
      color: $lightgreen;
    }

    .date-subtitle {
      color: $softBlack;
      font-size: rm(15);
      line-height: rm(18);
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
    }

    span[class^='ic-'],
    span[class*=' ic-'] {
      width: rm(11);
      display: flex;
      height: rm(11);
      align-items: center;
      margin-top: rm(-12);
      transform: rotate(90deg);

      &::before {
        font-size: rm(11);
      }
    }
  }
}

/* Long Term */

.long-term {
  padding: rm(30) rm(64);

  .long-term-offers-box {
    padding: rm(16);
    background-color: $white;
    border-radius: rm(10);

    &.no-bg {
      background-color: unset;
    }

    .slick-arrow {
      top: rm(160);
    }

    .offer-card {
      width: calc((100vw - rm(11.5 * 16)) / 2);
      .offer-card-img-container {
        width: 100%;
        position: relative;
        .offer-card-img {
          width: 100%;
          object-fit: cover;
          border-radius: rm(10);
        }
        .best-offer {
          position: absolute;
          width: rm(280);
          height: rm(280);
          top: rm(-80);
          left: rm(-80);
        }
      }

      .card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rm(25) rm(30) rm(18) rm(30);

        .card-info {
          .card-title {
            color: $black;
            font-size: rm(24);
            font-weight: 700;
            line-height: rm(28);
            margin-bottom: rm(8);
            line-height: rm(38);
          }

          .card-text {
            color: $black;
            font-size: rm(17);
            font-style: normal;
            font-weight: 700;
            line-height: rm(15);
          }
        }
      }
    }
  }
}

/* Choose Car */

.choose-car {
  padding: rm(30) 0;

  .choose-car-title {
    font-weight: 700;
    color: $softBlack;
    font-size: rm(22);
    line-height: rm(25);
    margin-bottom: rm(25);
    text-transform: uppercase;
  }

  .choose-your-car-carousel {
    .choose-car-carousel-title {
      cursor: pointer;
      color: $hardGreen;
      font-size: rm(14);
      font-weight: bold;
      text-align: center;
      line-height: rm(19);
      margin-top: rm(10);
    }
  }
}

/* Long Term Cards */

.suggestion-cards {
  padding: rm(20) rm(18) rm(16);
  background-color: $white;

  .suggestion-card {
    border-radius: rm(12);
    overflow: hidden;
    background: $grey;
    height: 100%;

    .suggestion-card-img {
      height: rm(265);
      background-size: cover;
      background-position: center;
    }

    .suggestion-card-info {
      padding: rm(44) 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100% - rm(265));

      .suggestion-card-title {
        color: $lightBlack;
        font-size: rm(26);
        font-style: normal;
        font-weight: 700;
        line-height: rm(32);
        margin-bottom: rm(22);
        text-align: center;
      }

      .suggestion-card-text {
        max-width: rm(330);
        color: $lightBlack;
        font-size: rm(16);
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: rm(20);
        margin-bottom: rm(30);
        word-break: break-word;
      }
    }
  }
}

/* Welcome */

.welcome {
  padding: rm(55) 0 rm(65);
  display: flex;
  justify-content: center;
  align-items: center;

  .welcome-box {
    width: rm(1230);
    padding: rm(58) rm(140) rm(40) rm(100);
    background-color: $white;
    border-radius: rm(12);

    & > p {
      margin-bottom: rm(34);
    }

    .advantages {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: rm(25);

      :last-child {
        margin-bottom: 0;
      }

      .advantage-box {
        display: flex;
        align-items: center;
        gap: rm(20);

        .advantage-title {
          color: $black;
          font-size: rm(19.2);
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        img {
          width: rm(30);
          height: rm(30);
        }
      }
    }
  }
}

/* Car Rental */

.car-rental {
  margin-bottom: rm(60);
  position: relative;

  .car {
    left: 0;
    bottom: rm(-15);
    position: absolute;
    color: transparent;
    width: rm(571) !important;
    height: rm(249) !important;
  }

  .btn {
    z-index: 1;
  }

  .car-rental-container {
    padding: rm(70) 0 rm(64);
    background-color: $darkgreen;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;

    .car-rental-title {
      color: $white;
      font-size: rm(24);
      font-style: normal;
      font-weight: 700;
      line-height: rm(28);
      margin-bottom: rm(7);
    }

    .car-rental-subtitle {
      color: $white;
      font-size: rm(17);
      font-style: normal;
      font-weight: 700;
      line-height: rm(15);
      margin-bottom: rm(23);
    }
  }
}

/* Rate */

.rate {
  position: relative;

  &::before {
    position: absolute;
    content: ' ';
    top: rm(18);
    left: rm(12);
    width: calc(100% - rm(36));
    height: calc(100% - rm(36));
    border: rm(4) solid $lightgreen;
    border-radius: rm(12);
  }

  .rate-img {
    width: 100%;
    height: rm(344);
    background-size: cover;
    background-position: center;
  }

  .rate-info {
    padding: rm(32) rm(22);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .rate-title {
      color: $black;
      font-size: rm(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: rm(10);
    }

    .rate-subtitle {
      color: $black;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
      margin-bottom: rm(14);
      word-wrap: break-word;
    }
  }
}

/* Extensive */

.extensive {
  padding: rm(64) rm(32) rm(32);

  .extensive-cards {
    display: flex;
    justify-content: center;
    gap: rm(44);

    .extensive-card {
      width: rm(343);
      max-width: 100%;
      height: rm(120);
      background-color: $white;
      border-radius: rm(12);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: rm(16);

      [class^='ic-'],
      [class*=' ic-'] {
        &::before {
          font-size: rm(50);
        }
      }

      .extensive-card-info {
        display: flex;
        flex-direction: column;
        gap: rm(10);

        .extensive-card-text {
          color: $black;
          font-size: rm(18);
          font-style: normal;
          font-weight: 700;
          line-height: rm(34);
          margin-bottom: 0;
          max-width: rm(210);
        }
      }
    }
  }
}

/* FAQ */

.faq {
  padding: rm(55) 0 rm(70);

  .accordion-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rm(66) 0 rm(42);

    .accordion {
      padding: rm(10) rm(2);
      max-width: rm(720);
      width: 100%;
      border: none;
      text-align: left;
      line-height: rm(21);
      outline: none;
      cursor: pointer;
      transition: 0.4s;
      font-size: rm(15);
      color: $black;
      font-style: normal;
      font-weight: 700;
      background-color: transparent;
      border-bottom: rm(1) solid $lightgreen;
      margin-bottom: rm(15.5);
      letter-spacing: rm(0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:after {
        float: right;
        content: '\e901';
        font-size: rm(20);
        margin-left: rm(5);
        color: $lightgreen;
        font-family: var(--icoFont);
      }

      &.active {
        &:after {
          content: '\e902';
          color: $lightgreen;
        }

        + .panel {
          display: block;
          width: 100%;

          p {
            word-wrap: break-word;
          }
        }
      }
    }

    .panel {
      padding: rm(15) 0 rm(35);
      display: none;
      max-width: rm(720);
      color: $black;
      font-size: rm(15);
      font-style: normal;
      font-weight: 400;

      * {
        all: revert !important;
      }
    }
  }
}

/* Blog Posts */

.blog-posts {
  display: flex;
  position: relative;
  justify-content: center;

  .blog-posts-img {
    z-index: -1;
    position: absolute;
  }

  .blog-posts-box {
    max-width: rm(720);
    margin: rm(100) 0;
    background-color: $white;
    border-radius: rm(12);
    padding: rm(12);

    .blog-posts-border {
      border-radius: rm(12);
      border: rm(4) solid $lightgreen;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: rm(30) rm(50) rm(20);

      .blog-posts-title {
        color: $black;
        text-align: center;
        font-size: rm(36);
        font-style: normal;
        font-weight: 700;
        margin: rm(12) 0 rm(16) 0;
      }

      .blog-posts-subtitle {
        color: $lightBlack;
        text-align: center;
        font-size: rm(18);
        font-style: normal;
        font-weight: 700;
        margin-bottom: rm(12);
      }

      .big-btn {
        padding: rm(15) rm(132);
      }
    }
  }
}

/* Footer */

footer {
  background-color: $white;
}

.footer {
  padding: rm(65) rm(60) rm(40);
  background-color: $white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .footer-left-menu {
    display: flex;
    flex-direction: column;

    &:hover {
      color: $green !important;
    }

    .footer-left-menu-item {
      display: flex;
      gap: rm(15);
      align-items: center;
      margin-bottom: rm(20);

      a {
        color: $black;
        font-size: rm(16);
        font-style: normal;
        font-weight: 400;
        line-height: rm(20);
        text-decoration: underline;
        margin-bottom: 0;
      }

      [class^='ic-'],
      [class*=' ic-'] {
        &::before {
          font-size: rm(24);
          color: $lightgreen;
        }
      }
    }
  }

  .footer-social-menu {
    display: flex;
    gap: rm(24);
    margin-bottom: rm(78);

    .footer-social-menu-item {
      img {
        width: rm(40);
        height: rm(40);
      }
    }
  }

  .footer-logo {
    max-width: rm(240);
  }

  .footer-right {
    display: flex;
    flex-wrap: wrap;
    gap: rm(70);
  }

  .footer-right-menu-title {
    color: $lightBlack;
    font-size: rm(14);
    font-style: normal;
    font-weight: 700;
    line-height: rm(18);
    text-transform: uppercase;
    padding-bottom: rm(10);
    text-decoration: none;

    &:hover {
      color: $green;
    }
  }

  .footer-right-menu {
    display: flex;
    flex-direction: column;

    .footer-right-menu-item {
      display: flex;
      gap: rm(6);
      color: $lightgreen;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(18);
      margin-bottom: rm(10);
      text-decoration: none;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }

      [class^='ic-'],
      [class*=' ic-'] {
        font-size: rm(14);

        &::before {
          font-size: rm(14);
          color: $lightgreen;
        }
      }
    }
  }
}

.footer-divider {
  padding: 0 rm(58);

  .divider {
    width: 100%;
    height: rm(2);
    background-color: $grey;
  }
}

.footer-terms {
  background-color: $white;
  padding: rm(26) 0 rm(18);
  display: flex;
  justify-content: center;
  gap: rm(12);
  flex-wrap: wrap;

  .footer-terms-item {
    color: $black;
    font-size: rm(13);
    font-style: normal;
    font-weight: 400;
    line-height: rm(20);
  }
}

/* --------- Landing Pages --------- */

/* Default Styles */

.landing-title {
  color: $black;
  font-size: rm(24);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: rm(22);
}

.landing-subtitle {
  color: $black;
  text-align: center;
  font-size: rm(18);
  font-style: normal;
  font-weight: 400;
  line-height: rm(24);
  margin-bottom: rm(50);
}

.landing-text {
  color: $black;
  font-size: rm(18);
  font-style: normal;
  font-weight: 400;
  line-height: rm(24);
  word-wrap: break-word;

  ul {
    margin-left: rm(20);
  }

  &span,
  a {
    color: $green;
    text-decoration: underline;
  }
}

.landing-img-box {
  width: 100%;
  height: rm(383);
  background-size: cover;
  background-position: center;
  margin-bottom: rm(70);
}

/* Welcome Our */

.welcome-our {
  padding: 0 rm(60) 0 rm(100);
}

/* One Way Rentals */

.one-way-rentals {
  padding: rm(30) rm(85) 0 rm(152);
}

/* Transportation Sevices */

.collaborated {
  padding: rm(100);

  .slick-slider {
    .slick-slide {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .slick-arrow {
      top: 40%;
    }
  }

  .collaborated-carousel-box {
    display: flex;
    width: fit-content;
    align-items: center;
    flex-direction: column;

    .collaborated-carousel-box-img {
      height: rm(352);
      width: rm(258);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: rm(12);
      margin-bottom: rm(24);
    }

    .collaborated-carousel-box-title {
      font-size: rm(20);
      font-style: normal;
      font-weight: 700;
      margin: 0.67rem 0;
      text-transform: uppercase;
    }
  }
}

/* About Us */

.about-us {
  padding: rm(110) 0;
  position: relative;

  .about-us-car {
    position: absolute;
    top: rm(50);
    left: 0;
    width: rm(302);
    height: rm(37);
  }
}

.about-gallery {
  img {
    height: auto !important;
  }
}

.info {
  padding: rm(114) 0 rm(46) 0;

  .info-text {
    padding: 0 rm(40);
    color: $lightgrey;
    font-size: rm(20);
    font-style: normal;
    font-weight: 600;
    line-height: rm(47);
    text-transform: uppercase;
  }

  .info-divider {
    width: 100%;
    height: rm(4);
    background-color: $green;
    margin-top: rm(42);
  }
}

.info-cards {
  padding-bottom: rm(77);

  .row {
    gap: rm(30);
    justify-content: center;

    .w-50 {
      width: calc(50% - #{rm(15)}) !important;
    }
  }

  .info-card {
    height: 100%;
    padding: rm(17) rm(34) rm(38);
    background-color: $white;
    border-radius: rm(12);
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-height: rm(184);
      margin-bottom: rm(40);
    }

    .info-card-title {
      color: $lightgreen;
      text-align: center;
      font-size: rm(32);
      font-style: normal;
      font-weight: 700;
      margin-bottom: rm(30);
    }

    .info-card-text {
      color: $black;
      text-align: center;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
    }
  }
}

/* Car Lease */

.car-lease-preview {
  padding: rm(70) 0;

  .car-lease-banner {
    object-fit: contain;
    max-height: rm(343);
  }
}

.car-release-benefits {
  padding: rm(80) 0 rm(60);
  margin-bottom: rm(90);
  background-color: $lightgreen;

  .benefits-title {
    color: $white;
    text-align: center;
    font-size: rm(24);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: rm(50);
    letter-spacing: rm(0.1);
  }

  .benefits-cards-title {
    color: $white;
    font-size: rm(24);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: rm(22);
  }

  .benefits-cards-text {
    color: $white;
    font-size: rm(16);
    font-style: normal;
    font-weight: 600;
    line-height: rm(24);
  }

  .benefits-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: rm(30);
    overflow-y: auto;

    .benefits-card {
      min-width: rm(58);
      min-height: rm(54);
      background-color: $white;
      border-radius: rm(8);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: rm(9);

      img {
        width: rm(24);
        height: rm(24);
        object-fit: contain;
      }

      &.active {
        background-color: $yellow;

        [class^='ic-'],
        [class*=' ic-'] {
          &::before {
            color: $white;
          }
        }
      }
    }
  }
}

.popular-cars {
  padding: 0 rm(65);

  .popular-cars-carousel {
    .popular-cars-img {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: rm(150);

      img {
        max-width: rm(240);
        height: min-content;
        position: relative;
        z-index: 11;
      }
    }

    .popular-cars-box {
      margin-top: rm(-40);
      display: flex;
      flex-direction: column;
      background-color: $white;
      border: rm(1) solid $lightgrey;
      border-radius: rm(12);
      position: relative;

      .popular-cars-price {
        padding: rm(12) rm(18) 0;

        .popular-cars-price-title {
          color: $black;
          font-size: rm(16);
          font-style: normal;
          font-weight: 700;
          margin: 0.67rem 0;
        }

        .popular-cars-price-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .popular-cars-price-text {
            color: $black;
            font-size: rm(13);
            font-style: normal;
            font-weight: 400;
          }

          .popular-cars-price-badge {
            min-width: rm(102);
            height: rm(50);
            border: rm(2) solid $hardGreen;
            border-radius: rm(12);
            color: $black;
            font-size: rm(14);
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 rm(10);
            text-wrap: nowrap;
          }
        }
      }

      .popular-cars-btn-box {
        padding: rm(12) rm(40) rm(20);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .popular-cars-box-info {
        padding: rm(58) rm(18) 0;

        .popular-cars-box-title {
          color: $black;
          font-size: rm(14);
          font-style: normal;
          font-weight: 700;
          line-height: rm(18);
          text-transform: uppercase;
          margin: rm(12) 0;
        }

        .popular-cars-box-subtitle {
          color: $black;
          font-size: rm(18);
          font-style: normal;
          font-weight: 400;
          line-height: rm(24);
          margin-bottom: rm(10);
        }

        .popular-cars-box-menu {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: rm(12);

          .popular-cars-box-menu-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .popular-cars-box-menu-icon {
              &.small {
                [class^='ic-'],
                [class*=' ic-'] {
                  &::before {
                    font-size: rm(10);
                  }
                }
              }

              height: rm(46);
              display: flex;
              justify-content: center;
              align-items: center;

              [class^='ic-'],
              [class*=' ic-'] {
                &::before {
                  color: #757575;
                  font-size: rm(20);
                }
              }
            }

            img {
              max-width: rm(24);
            }

            .popular-cars-box-menu-text {
              color: $lightBlack;
              font-size: rm(13);
              font-style: normal;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.more-options {
  padding: 0 rm(100) rm(120);

  .more-options-carousel {
    .more-options-carousel-img {
      display: flex;
      justify-content: center;

      img {
        max-width: rm(260);
        height: min-content;
        margin-bottom: rm(32);
      }
    }

    .more-options-carousel-title {
      color: $black;
      text-align: center;
      font-size: rm(18);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
      margin-bottom: rm(12);
    }

    .more-options-carousel-link {
      color: $hardGreen;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: rm(14);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}

/* Month And More */

.special-offers,
.month-more-preview {
  padding: rm(60) 0 rm(40);
}

.special-offers-info,
.month-more-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rm(140);

  .special-offers-info-box,
  .month-more-info-box {
    max-width: rm(940);

    .special-offers-info-title,
    .month-more-info-title {
      color: $black;
      font-size: rm(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 0.67rem;
      margin-bottom: rm(22);
    }

    ol {
      margin-left: rm(16);
    }
  }
}

/* Rent car Yerevan */

.rent-car-yerevan {
  padding: rm(60) 0 0;

  .line {
    width: 100%;
    height: rm(1);
    background-color: $lightgreen;
    margin-top: rm(60);
  }
}

.rent-car-info {
  padding: rm(40) 0 rm(70);

  .rent-car-info-box {
    display: flex;
    gap: rm(16);
    align-items: flex-start;
    margin-bottom: rm(14);

    .circle {
      width: rm(24);
      height: rm(24);
      border-radius: 50%;
      background-color: $green;
    }

    [class^='ic-'],
    [class*=' ic-'] {
      &::before {
        font-size: rm(24);
        color: $lightgreen;
      }
    }

    a.rent-car-info-text {
      text-decoration: underline;
    }

    .rent-car-info-text {
      color: $black;
      font-size: rm(16);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
    }
  }
}

.rent-car-map {
  width: 100%;
  height: rm(322);
}

/* Find A Reservation */

.find-reservation-preview {
  padding: rm(90) rm(10) rm(110);
  display: flex;
  flex-direction: column;
  align-items: center;

  .find-reservation-box {
    max-width: rm(632);
    width: 100%;

    .find-reservation-title {
      color: #000;
      font-size: rm(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: rm(20);
    }

    .find-reservation-text {
      opacity: 0.8;
      color: #000;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);

      & > b {
        opacity: 1;
      }
    }
  }
}

.find-reservation-form {
  display: flex;
  justify-content: center;

  .find-reservation-box {
    max-width: rm(910);
    width: 100%;

    .find-reservation-box-header {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: rm(12);
      margin-bottom: rm(24);

      .find-reservation-box-title {
        margin: 0;
        color: $lightBlack;
        font-size: rm(36);
        font-style: normal;
        font-weight: 700;
        line-height: rm(32);
        text-transform: capitalize;
      }

      .find-reservation-box-or {
        color: $lightgrey;

        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(16);
        text-transform: uppercase;
      }

      .find-reservation-box-modify {
        color: $lightgreen;

        font-size: rm(14);
        font-style: normal;
        font-weight: 700;
        line-height: rm(18);
        text-decoration-line: underline;
        text-transform: uppercase;
      }
    }
  }
}

.form-control {
  &.text-area {
    min-height: rm(160);
    padding: rm(12);
    resize: none;
  }
}

.reservation-steps {
  .container {
    padding: 0;
  }
}
