@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014/DIN2014-Regular.ttf') format('truetype');
  // src: url('../fonts/DINAlternate/DINAlternateRegular.otf') format('opentype');
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014/DIN2014-DemiBold.ttf') format('truetype');
  // src: url('../fonts/DINAlternate/DINAlternateMedium.otf') format('opentype');
}

@font-face {
  font-weight: bold;
  font-style: normal;
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014/DIN2014-Bold.ttf') format('truetype');
  // src: url('../fonts/DINAlternate/DINAlternateBold.otf') format('opentype');
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: 'DIN 2014';
  src: url('../fonts/DIN2014/DIN2014-ExtraBold.ttf') format('truetype');
  // src: url('../fonts/DINAlternate/DINAlternateBold.otf') format('opentype');
}

$transition: 0.3s ease-in-out;
$black: #000000;
$lightBlack: #181918;
$softBlack: #222;
$white: #ffffff;
$green: #15804d;
$grey: #f3f3f3;
$darkgrey: #c3c3c3;
$textDarkgrey: #656565;
$lightgrey: #828282;
$lightgreen: #169a5a;
$darkgreen: #006639;
$hardGreen: #127f4a;
$yellow: #ffc107;
$secondary-dark-gray: #393a3b;

$DINAlternate: 'DIN 2014';
$IconFont: 'icoFont';

$transition: 0.3s ease-in-out;
