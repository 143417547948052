.over {
  width: 100%;
  margin-top: rm(27);
  position: relative;
  background-color: $white;
  left: 0;
  z-index: 2;
  box-shadow: 0 rm(4) rm(4) 0 rgba(0, 0, 0, 0.25);
  border-radius: 0 0 rm(20) rm(20);
  padding: rm(15);
  max-height: rm(300);
  overflow-y: auto;

  .over-title {
    display: flex;
    gap: rm(5);
    align-items: center;
    margin-bottom: rm(8);

    p {
      color: $black;
      font-weight: 600;
    }
  }

  .over-items {
    margin-left: rm(30);
    border-bottom: rm(1) solid $darkgrey;
    margin-bottom: rm(20);

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .over-item {
      padding: rm(20) rm(10);
      border-radius: rm(8);
      cursor: pointer;

      &:hover {
        background-color: $grey;

        .over-item-box-title {
          color: $green;
        }
      }

      &:last-child {
        margin-bottom: rm(10);
      }

      .over-item-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .over-item-box-info {
          display: flex;
          flex-direction: column;
          gap: rm(5);

          .over-item-box-title {
            font-size: rm(15);
            font-weight: 600;
          }

          .over-item-box-subtitle {
            font-size: rm(13);
          }
        }
      }
    }
  }
}

.reservation-btn {
  cursor: pointer;
  transition: all 0.2s;

  &.colored {
    &:hover {
      background-color: $darkgreen;
    }
  }

  &.bordered {
    &:hover {
      color: $white;
      border-color: $darkgreen;
      background-color: $darkgreen;
    }
  }
}

.edit-btn {
  cursor: pointer;

  &:hover {
    background-color: $green;
    color: $white;
  }
}

.extras-item.included {
  border: rm(1) solid $darkgrey !important;
}

.filter-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($color: #000, $alpha: 0.7);
  display: none;

  &.open {
    display: block;
  }

  .filter-modal {
    background-color: $white;
    padding: rm(20) rm(42);
  }
}

/* Default Styles */

.gap-60 {
  gap: rm(60);
}

.gap-30 {
  gap: rm(30);
}

.pt-24 {
  padding-top: rm(24);
}

.pb-24 {
  padding-bottom: rm(24);
}

.mr-auto {
  margin-right: auto;
}

.mt-40 {
  margin-top: rm(40);
}

/* Popover Home Menu */

.header-menu {
  .header-menu-nav {
    .popover-wrapper {
      position: unset !important;
      .popover {
        width: 100vw;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
      }
    }
  }
}
