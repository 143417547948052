/* Header */

.header-menu {
  justify-content: space-between;
  flex-wrap: nowrap;

  .header-menu-nav {
    width: 100%;

    .header-menu-link {
      padding: 0;
    }
  }
}

.header-navbar {
  .header-settings {
    a {
      text-decoration: none;

      & > p {
        text-decoration: underline;
      }
    }
  }
}

/* Default Configs */

.big-container {
  max-width: rm(1280);
  margin: 0 auto;
  padding: 0 rm(20);
}

/* Custom Select */

.custom-select {
  position: relative;
  // width: rm(370);
  width: 100%;

  select {
    display: none;
  }
}

.select-selected {
  background-color: $white;
  width: 100%;
  max-width: rm(390);
  height: rm(50);

  &::after {
    position: absolute;
    content: '';
    top: rm(25);
    right: rm(10);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border: rm(6) solid transparent;
    border-color: $hardGreen transparent transparent transparent;
  }

  &.select-arrow-active:after {
    border-color: transparent transparent $hardGreen transparent;
    top: rm(20);
  }

  &.active {
    background-color: $darkgrey;
  }
}

.select-items div,
.select-selected {
  color: $black;
  padding: rm(8) rm(16);
  border: rm(2) solid $darkgrey;
  cursor: pointer;
  height: rm(50);
  user-select: none;
  display: flex;
  align-items: center;
}

.select-items {
  position: absolute;
  background-color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-select-title {
  color: $lightBlack;
  font-size: rm(14);
  font-style: normal;
  font-weight: 400;
  line-height: rm(18);
  margin-bottom: rm(5);
}

/* Fleet */

.fleet {
  padding: rm(44) 0 rm(54);

  .custom-select {
    min-width: rm(390);
  }

  .fleet-title {
    color: $black;
    font-size: rm(36);
    font-style: normal;
    font-weight: 400;
    line-height: rm(40);
  }

  .fleet-cards {
    display: flex;
    flex-direction: column;
    gap: rm(24);

    .fleet-card {
      width: 100%;
      padding: rm(30) rm(16);
      border-radius: rm(12);
      background-color: $white;

      .fleet-card-header {
        display: flex;
        gap: rm(30);
        margin-bottom: rm(28);
        padding-bottom: rm(12);
        border-bottom: rm(4) solid $lightBlack;

        .fleet-card-title {
          color: $hardGreen;
          font-size: rm(24);
          font-style: normal;
          font-weight: 400;
          line-height: rm(24);
        }

        .fleet-card-info {
          display: flex;
          gap: rm(8);
          align-items: center;
          font-size: rm(14);
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .fleet-card-body {
        display: flex;
        gap: rm(40);
        padding: 0 rm(10);
        align-items: flex-start;

        img {
          max-width: rm(312);
        }

        .fleet-card-text {
          color: $lightBlack;
          font-size: rm(16);
          font-style: normal;
          font-weight: 400;

          & > a {
            color: $hardGreen;
            text-decoration: underline;
          }
        }
        .fleet-info-details-text {
          &.title {
            text-transform: uppercase;
          }
        }
      }

      .fleet-card-footer {
        margin-top: rm(-60);
      }
    }
  }
}

/* Default Styles */

.bgc-none {
  background-color: transparent;
}

/* Breadcrumb */

.breadcrumb {
  width: 100%;
  min-height: rm(42);
  padding: 0 rm(60);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: rm(1) solid $darkgrey;

  .breadcrumb-link {
    color: $lightgreen;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    margin-right: rm(26);
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: '/';
      position: absolute;
      top: 50%;
      right: rm(-18);
      transform: translate(-50%, -50%);
      color: $darkgrey;
      font-size: rm(14);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.active {
      color: $black;

      &:hover {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: none;
      }
    }
  }
}

/* Fleet Info */

.fleet-info {
  padding: rm(75) 0 rm(56);

  .fleet-info-title {
    color: $lightBlack;
    font-size: rm(36);
    font-style: normal;
    font-weight: 400;
  }

  .fleet-info-text {
    color: $lightBlack;
    font-size: rm(18);
    font-style: normal;
    font-weight: 400;
  }
}

.fleet-info-details {
  .fleet-info-details-title {
    color: $lightBlack;
    font-size: rm(24);
    font-style: normal;
    font-weight: 400;
    border-bottom: rm(4) solid $lightBlack;
    padding-bottom: rm(12);
    margin-bottom: rm(36);
  }

  .fleet-info-details-text {
    color: $lightBlack;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    margin-bottom: rm(30);
  }

  .fleet-info-details-features {
    margin-left: rm(20);
  }

  .fleet-info-details-list {
    display: flex;
    flex-direction: column;

    .fleet-info-details-list-item {
      display: flex;
      gap: rm(11);
      margin-bottom: rm(30);

      & > p {
        color: $black;
        font-size: rm(14);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

/* Blog */

.blog {
  padding: rm(88) 0 rm(52);

  .blog-title {
    color: $black;
    font-size: rm(24);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: rm(570);
    margin-bottom: rm(15);
  }

  .blog-text {
    color: $black;
    font-size: rm(14);
    font-style: normal;
    font-weight: 400;
    line-height: rm(24);
    margin-bottom: rm(60);

    & > a {
      color: $lightgreen;
      text-decoration: underline;
    }

    p {
      word-break: break-word;
    }
  }

  .blog-img {
    width: 100%;
    border-radius: rm(12);
    margin-bottom: rm(44);
  }
}

/* Contact Us */

.contact-us {
  padding: rm(58) 0 rm(94);

  .contact-us-card {
    width: 100%;
    padding: rm(30) rm(10) rm(54);
    border: rm(1) solid $darkgrey;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: $black;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }

    .contact-us-card-icon {
      margin-bottom: rm(24);
    }

    .contact-us-card-link {
      color: $hardGreen;
      font-size: rm(21);
      font-style: normal;
      font-weight: 400;
      margin-bottom: rm(14);
    }
  }

  .line {
    width: 100%;
    height: rm(1);
    background-color: $lightgreen;
  }

  .contact-us-box {
    display: flex;
    gap: rm(20);
    align-items: flex-start;
    margin-bottom: rm(18);

    [class^='ic-'],
    [class*=' ic-'] {
      &::before {
        font-size: rm(20);
        color: $lightgreen;
      }
    }

    & > p,
    & > a {
      color: $black;
      font-size: rm(16);
      font-style: normal;
      font-weight: 400;
      line-height: rm(24);
    }
  }
}

// Steps Indicator
.stepper {
  padding: 3.125rem 3.125rem 1.875rem 3.75rem;
  background-color: $white;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.stepper .stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.stepper .stepper-item .stepper-item-info {
  text-align: center;
}

.stepper .stepper-item .stepper-item-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.375rem;
}

.stepper .stepper-item .stepper-item-title.active img {
  display: block;
}

.stepper .stepper-item .stepper-item-title.active .stepper-child {
  display: none;
}

.stepper .stepper-item .stepper-item-title .stepper-child {
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 50%;
  background-color: $lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
}

.stepper .stepper-item .stepper-item-title img {
  width: 1.5625rem;
  height: 1.5625rem;
  display: none;
}

.stepper .stepper-item .stepper-item-title p {
  color: $black;
  font-size: 1.2rem !important;
  font-style: normal;
  font-weight: 500;
}

.stepper .stepper-item .stepper-item-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stepper .stepper-item .stepper-item-info p {
  color: $black;
  font-size: 0.95rem !important;
  font-style: normal;
}

.stepper .stepper-line {
  width: 100%;
  height: 0.0625rem;
  background-color: $grey;
  margin-top: 0.78125rem;
}

/*  Choose Location*/

.choose-location {
  padding: rm(20);
  background-color: $white;

  .choose-location-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .choose-location-results {
      display: flex;
      gap: rm(22);
      align-items: center;

      .choose-location-result {
        color: $black;
        font-size: rm(17);
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        & > span {
          color: $textDarkgrey;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .change-location {
        display: flex;
        align-items: center;
        gap: rm(4);
        color: $green;
        font-size: rm(17);
        font-style: normal;
        font-weight: 700;
        line-height: rm(24);

        img {
          width: rm(17);
          height: rm(18);
        }
      }
    }

    .choose-location-tools {
      display: flex;
      align-items: center;
      gap: rm(20);

      .choose-location-tool {
        display: flex;
        gap: rm(5);

        p {
          color: $green;
          font-size: rm(15);
          font-style: normal;
          font-weight: 700;
          line-height: rm(24);
        }
      }
    }
  }
}
