@import '../../assets/scss/mixin.scss';

.date-range-picker-panel {
  .item-bg {
    border: rm(1) solid #eff1f5;
    background-color: $white;
    max-height: max-content !important;
    padding: 0 !important;

    .date-range {
      border-radius: inherit;
      width: max-content !important;
    }
  }
}

.custom-date-range-picker {
  margin: 0 !important;
  padding: 0;

  .months-container {
    gap: rm(6);
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .month-container {
      flex: 1;
      padding: rm(40) rm(40) 0 rm(40);

      &.rdp-caption_end {
        .caption-container {
          justify-content: flex-end;
        }
      }

      .caption-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        position: relative;

        .caption-label {
          text-transform: capitalize;
          color: $lightBlack;
          font-size: rm(18);
          line-height: rm(24);
          font-weight: 400;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
        }

        .nav {
          display: flex;
          align-items: center;
          justify-content: center;

          .nav-button {
            outline: none !important;
            border: none !important;
            border-radius: rm(4);
            background-color: $white;
            cursor: pointer;
            -webkit-border-radius: rm(4);
            -moz-border-radius: rm(4);
            -ms-border-radius: rm(4);
            -o-border-radius: rm(4);

            &:hover {

              [class^='ic-'],
              [class*=' ic-'] {
                &::before {
                  color: $white;
                }
              }

              background-color: $darkgreen;
            }

            [class^='ic-'],
            [class*=' ic-'] {
              &::before {
                color: $lightgreen;
                font-size: rm(34);
              }
            }
          }
        }
      }

      .calendar-table {
        width: 100%;

        .calendar-header {
          display: flex;
          width: 100%;
          padding-top: rm(20);
          border-bottom: unset;
          justify-content: center;
        }

        .week-day {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $secondary-dark-gray;
          width: rm(50);
          height: rm(50);
          font-size: rm(14);
          font-weight: 400;
          line-height: rm(18);
        }

        .calendar-row {
          display: flex;
          width: 100%;
          margin: rm(4) 0;
          justify-content: center;
          background-color: $white;

          .calendar-day-container:first-of-type {
            .calendar-day.day-in-range {
              border-top-left-radius: rm(4);
              border-bottom-left-radius: rm(4);
            }

            .calendar-day.rdp-day_range_end::after {
              border-top-left-radius: rm(4);
              border-bottom-left-radius: rm(4);
            }
          }

          .calendar-day-container:last-of-type {
            .calendar-day.day-in-range {
              border-top-right-radius: rm(4);
              border-bottom-right-radius: rm(4);
            }

            .calendar-day.rdp-day_range_start::after {
              border-top-right-radius: rm(4);
              border-bottom-right-radius: rm(4);
            }
          }
        }

        .calendar-day-container {
          position: relative;
          width: rm(50);
          height: rm(50);

          .calendar-day {
            cursor: pointer;
            position: relative;
            border: none;
            width: rm(50);
            height: rm(50);
            color: $textDarkgrey;
            text-align: center;
            font-size: rm(14);
            line-height: rm(18);
            font-weight: 400;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            position: absolute;
            background-color: $white;

            &.rdp-day_range_start,
            &.rdp-day_range_end {
              background: none;

              &::before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: rm(3);
                border-radius: inherit;
                background-color: $white;
                z-index: -1;
              }

              &::after {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: inherit;
                background-color: $darkgreen;
                z-index: -2;
              }
            }

            &.rdp-day_range_start {
              &::after {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }

            &.rdp-day_range_end {
              &::after {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            &:not(.selected-date):hover {
              color: $white;
              background-color: $darkgreen;
            }

            &.selected-date {
              color: $darkgreen;
              background-color: $white;

              &.rdp-day_range_start.rdp-day_range_end {
                &::after {
                  border-radius: 50%;
                }
              }

              &.day-in-range {
                background-color: $darkgreen;
                color: $white;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
              }
            }

            &.day-hidden {
              display: none;
            }

            &.outside-date {
              display: none;
            }

            &.disabled-date {
              cursor: not-allowed;
            }

            &.day-today {
              &:not(.selected-date) {
                &:hover {
                  color: $white;
                  background-color: $darkgreen;
                }
              }
            }
          }
        }
      }
    }
  }
}

.date-range-picker-panel {
  .item-bg {
    display: flex;
    border-radius: rm(12) !important;
    overflow: hidden;

    .date-range {
      .range-toolbar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: rm(10);
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: rm(40);

        .different-location-box {
          margin: 0;
        }
      }
    }
  }
}

.pickup-return-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 1124px) {
  .preview .w-66:not(.search-input) .popover {
    width: 100% !important;
    top: rm(80) !important;

    .item-bg {
      width: 100% !important;
      flex-wrap: wrap;

      .date-range {
        width: 100% !important;
      }

      .months-container {
        flex-wrap: wrap !important;
      }
    }
  }
}